define("da/controllers/juries/jury/creations/creation/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    voteService: Ember.inject.service('vote'),
    category: Ember.computed.alias('voteService.category'),
    opened: false,
    className: Ember.computed('opened', function () {
      if (this.get('opened')) {
        return 'panel-button active';
      }

      return 'panel-button';
    }),
    classBack: Ember.computed('opened', function () {
      if (this.get('opened')) {
        return 'hide back';
      }

      return 'back';
    }),
    lockedOpen: Ember.computed('opened', function () {
      return this.get('opened') ? 'lockedOpen' : '';
    }),
    actions: {
      opened() {
        Ember.$('body').toggleClass('panel-open');

        if (this.get('opened')) {
          this.set('opened', false);
        } else {
          this.set('opened', true);
        }
      },

      close() {
        this.transitionToRoute('juries.jury.creations');
      }

    }
  });

  _exports.default = _default;
});
define("da/routes/campagne/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    vimeo: Ember.inject.service(),
    auth: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.hash({
        campagnes: this.store.findAll('campagne'),
        campagne: this.store.createRecord('campagne', {
          sector: this.store.peekAll('sector').get('firstObject'),
          competition: this.store.peekAll('competition').get('firstObject')
        })
      });
    }
  });

  _exports.default = _default;
});
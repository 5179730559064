define("da/components/user/list-user", ["exports", "da/components/jury/creation-list"], function (_exports, _creationList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _creationList.default.extend({});

  _exports.default = _default;
});
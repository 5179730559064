define("da/templates/components/user/user-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dKAzPtnn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bg\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mask\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n            \"],[7,\"span\",true],[8],[0,\"\\n                \"],[7,\"strong\",true],[10,\"class\",\"campagne-name\"],[8],[1,[24,[\"list\",\"agencyName\"]],false],[9],[0,\"\\n                \"],[7,\"span\",true],[10,\"class\",\"list-id\"],[8],[1,[24,[\"list\",\"id\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"annonceur-name\"],[8],[1,[24,[\"list\",\"name\"]],false],[0,\" \"],[1,[24,[\"list\",\"firstname\"]],false],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"creation-name\"],[8],[1,[24,[\"list\",\"email\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/user/user-item.hbs"
    }
  });

  _exports.default = _default;
});
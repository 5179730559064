define("da/services/upload", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),

    upload(file, progress, url, form) {
      return Ember.$.ajax({
        url: url,
        data: form,
        processData: false,
        contentType: false,
        type: 'POST',
        dataType: "json",
        xhr: function () {
          var xhr = new window.XMLHttpRequest(); //Download progress

          xhr.upload.addEventListener("progress", progress, false);
          return xhr;
        },
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    image(file, imageType, progress, creation_id) {
      var form = new FormData();
      form.append('upload', file);
      form.append('creation_id', creation_id);
      form.append('image_type', imageType);
      let url = _environment.default.host + '/api/v1/images';
      return this.upload(file, progress, url, form);
    },

    prev(file, imageType, progress, creation_id) {
      var form = new FormData();
      form.append('upload', file);
      form.append('creation_id', creation_id);
      form.append('image_type', imageType);
      let url = _environment.default.host + '/api/v1/images/prev';
      return this.upload(file, progress, url, form);
    },

    audio(file, progress, creation_id) {
      var form = new FormData();
      form.append('upload', file);
      form.append('creation_id', creation_id);
      let url = _environment.default.host + '/api/v1/audios';
      return this.upload(file, progress, url, form);
    },

    videos(creation_id, vimeo_path, video_type) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/videos',
        data: {
          creation_id: creation_id,
          vimeo_path: vimeo_path,
          video_type: video_type
        },
        type: 'POST',
        dataType: "json",
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    }

  });

  _exports.default = _default;
});
define("da/controllers/auth/forget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    actions: {
      forget(model) {
        this.get('auth').forget(model.email).then(success => {
          this.set('dialog.show', true);
          this.set('dialog.title', 'Demande de changement de mot de passe');
          this.set('dialog.message', 'Un Email a été envoyé à l\'adresse ' + model.email);
        }, data => {
          this.set('errors', data.responseJSON.errors.email);
        });
      }

    }
  });

  _exports.default = _default;
});
define("da/components/creation/video-item", ["exports", "da/components/creation/item-ui"], function (_exports, _itemUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _itemUi.default.extend({
    galleries: Ember.computed('image.length', function () {
      return this.get('image');
    }),
    maxImages: Ember.computed('galleries.length', function () {
      return this.get('galleries.length') >= 8 ? true : false;
    })
  });

  _exports.default = _default;
});
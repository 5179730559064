define("da/controllers/auth/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),

    login(email, password) {
      this.get('auth').login(email, password).then(response => {
        this.get('auth').setToken(response.access_token, response.expire);
        this.transitionToRoute('campagne.index');
      }, error => {
        this.transitionToRoute('login', error);
      });
    },

    title: Ember.computed('isStudent', function () {
      if (this.get('isConcours')) {
        return 'Concours Futurs Désirables';
      }

      if (this.get('isStudent')) {
        return 'Concours Étudiant';
      }

      if (this.competition.libelle) {
        let title = this.competition.libelle.split('ᵉ').join('<sup>e</sup>');
        return title.split('°').join('<sup>e</sup>');
      }

      return 'Compétition & Concours';
    }),
    isStudent: Ember.computed('model.userApi.special', function () {
      return this.get('model.userApi.special') === 'student';
    }),
    isConcours: Ember.computed('model.userApi.special', function () {
      return this.get('model.userApi.special') === 'concours';
    }),
    isIndependant: Ember.computed('model.userApi.structure', function () {
      return this.get('model.userApi.structure.id') === '17';
    }),
    structureTitle: Ember.computed('isStudent', function () {
      if (this.get('isStudent')) {
        return 'École';
      }

      return 'Structure / Société';
    }),
    structureLabel: Ember.computed('isStudent', function () {
      if (this.get('isStudent')) {
        return 'Nom de l’école';
      }

      return 'Nom de la structure';
    }),
    competition: Ember.computed(function () {
      return this.get('store').peekAll('competition').get('firstObject');
    }),
    actions: {
      register(model) {
        let password = model.get('password');
        let email = model.get('email');
        model.save().then(user => {
          this.login(email, password);
        });
      }

    }
  });

  _exports.default = _default;
});
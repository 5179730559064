define("da/services/vote", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    auth: Ember.inject.service(),
    store: Ember.inject.service(),
    openedPanel: false,
    showShortlist: false,
    showNomination: false,
    showMention: false,
    showPrice: false,
    keyBoardListenner: true,
    showNbVote: true,
    recursive: false,
    sortOptions: [{
      id: 'annonceur',
      name: 'Annonceur'
    }, {
      id: 'nbVotes',
      name: 'Nombre de vote'
    }],
    selectedSortType: null,
    creation: Ember.computed.alias('creationCategory.creation'),
    category: Ember.computed.alias('creationCategory.category'),
    sortProperties: Ember.computed('selectedSortType', function () {
      if (this.selectedSortType != null) {
        return [this.get('selectedSortType.id') + ':desc'];
      }

      return ['category.ordre:asc', 'annonceurSort:asc', 'campagneId:asc', 'id:asc'];
    }),
    searchProperties: ['id', 'annonceur', 'campagneName'],
    sortedContent: Ember.computed.sort('lists', 'sortProperties'),
    filteredLists: Ember.computed('recursive', 'lists.{length,@each.isFavorite}', 'search', 'selectedCategory', 'selectedSortType', 'showShortlist', 'showNomination', 'showMention', 'showPrice', function () {
      var data = this.get('sortedContent');

      if (!Ember.isEmpty(this.get('selectedCategory'))) {
        data = data.filterBy('category.id', this.get('selectedCategory.id'));
      }

      if (this.get('showShortlist')) {
        data = this.get('recursive') ? this.recursiveShortlist(data) : data.filterBy('userCreation.shortlist', true);
      }

      if (this.get('showNomination')) {
        data = this.get('recursive') ? this.recursiveNomination(data) : data.filterBy('userCreation.nomination', true);
      }

      if (this.get('showMention')) {
        data = this.get('recursive') ? this.recursiveMention(data) : data.filterBy('userCreation.mention', true);
      }

      if (this.get('showPrice')) {
        data = this.get('recursive') ? this.recursivePrice(data) : data.filterBy('userCreation.price', true);
      }

      if (!Ember.isEmpty(this.get('search'))) {
        data = data.filter(item => {
          var objects = item.getProperties(this.get('searchProperties'));
          return this.checkMatch(objects);
        });
      }

      return data;
    }),
    categories: Ember.computed('lists.length', function () {
      return this.get('lists').mapBy('category').uniqBy('id').sortBy('ordre');
    }),
    nbUserCreation: Ember.computed('lists.length', function () {
      return this.get('lists').filter(function (list) {
        return list.get('userCreation.id') != undefined;
      }).length;
    }),
    nbCreation: Ember.computed('lists.length', function () {
      return this.get('lists').uniqBy('creation.id').get('length');
    }),
    isPresident: Ember.computed('jury.president_id', 'user.id', function () {
      return this.get('jury.presidentId') === this.get('user.id') ? true : false;
    }),
    canAccessVotes: Ember.computed('user.isAdmin', 'isPresident', function () {
      return this.get('user.isAdmin') || this.get('isPresident') ? true : false;
    }),
    canAccessJury: Ember.computed('user.isAdmin', 'isPresident', function () {
      return this.get('user.isAdmin') || this.get('user.isSupervisor') || this.get('isPresident') ? true : false;
    }),
    isSupervisor: Ember.computed('user.isSupervisor', function () {
      return this.get('user.isSupervisor') ? true : false;
    }),
    hasFavorite: Ember.computed('nbUserCreation', 'canAccessVotes', function () {
      return this.get('nbUserCreation') > 0 && this.get('canAccessVotes') ? true : false;
    }),
    hasFilter: Ember.computed('search', 'selectedCategory', 'selectedSortType', function () {
      return !Ember.isEmpty(this.get('search')) || !Ember.isEmpty(this.get('selectedCategory')) ? true : false || !Ember.isEmpty(this.get('selectedSortType')) ? true : false;
    }),

    checkMatch(objects) {
      var match = false;

      for (var key in objects) {
        if (objects[key].toLowerCase().indexOf(this.get('search').toLowerCase()) != -1) {
          match = true;
          return true;
        }
      }

      return match;
    },

    recursiveShortlist(data) {
      data = data.filter(function (elem) {
        var shortlist = elem.get('userCreation.shortlist');
        var nomination = elem.get('userCreation.nomination');
        var mention = elem.get('userCreation.mention');
        var price = elem.get('userCreation.price');

        if (shortlist && nomination === false && mention === false && price === false) {
          return true;
        }

        return false;
      });
      return data;
    },

    recursiveMention(data) {
      data = data.filter(function (elem) {
        var shortlist = elem.get('userCreation.shortlist');
        var nomination = elem.get('userCreation.nomination');
        var mention = elem.get('userCreation.mention');
        var price = elem.get('userCreation.price');

        if (shortlist === false && nomination === false && mention === true && price === false) {
          return true;
        }

        return false;
      });
      return data;
    },

    recursiveNomination(data) {
      data = data.filter(function (elem) {
        var shortlist = elem.get('userCreation.shortlist'); //jaune

        var nomination = elem.get('userCreation.nomination'); //bleu

        var mention = elem.get('userCreation.mention'); //blanc

        var price = elem.get('userCreation.price'); //rouge

        if (shortlist && nomination && mention === false && price === false) {
          return true;
        }

        return false;
      });
      return data;
    },

    recursivePrice(data) {
      data = data.filter(function (elem) {
        var shortlist = elem.get('userCreation.shortlist');
        var nomination = elem.get('userCreation.nomination');
        var mention = elem.get('userCreation.mention');
        var price = elem.get('userCreation.price');

        if (shortlist === false && nomination === false && mention === false && price) {
          return true;
        }

        return false;
      });
      return data;
    },

    setup(creationCategory, jury) {
      this.set('creationCategory', creationCategory);
      this.set('jury', jury);
      var creation_id = creationCategory.get('creation.id');
      var category_id = creationCategory.get('category.id');
      var jury_id = jury.id;
      return new Ember.RSVP.Promise(resolve => {
        Ember.RSVP.allSettled([this.fetchVote(creation_id, category_id, jury_id), this.fetchFavorite(creationCategory, jury)]).then(([vote, favorite]) => {
          this.initVote(vote.value);
          this.initFavorite(favorite.value);
          resolve(vote, favorite);
        });
      });
    },

    initVote: function (vote) {
      var vote_store = false;

      if (vote.data.id) {
        this.get('store').pushPayload(vote);
        vote_store = this.get('store').peekRecord('vote', vote.data.id);
      } else {
        vote_store = this.get('store').createRecord('vote', {
          creation: this.get('creation'),
          category: this.get('category'),
          jury: this.get('jury')
        });
      }

      this.set('vote', vote_store);
    },
    initFavorite: function (favorite) {
      var favorite_store;

      if (favorite.data.id) {
        this.get('store').pushPayload(favorite);
        favorite_store = this.get('store').peekRecord('user-creation', favorite.data.id);
      } else {
        favorite_store = this.get('store').createRecord('user-creation', {
          creation: this.get('creation'),
          category: this.get('category'),
          jury: this.get('jury')
        });
      }

      this.set('favorite', favorite_store);
    },

    fetchVote(creation_id, category_id, jury_id) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/jury/votes/user_vote/' + creation_id + '/' + category_id + '/' + jury_id,
        type: "GET",
        dataType: "json",
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    fetchFavorite(creationCategory, jury) {
      var creation_id = creationCategory.get('creation.id');
      var category_id = creationCategory.get('category.id');
      var jury_id = jury.get('id');
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/jury/user_creations/favorite/' + creation_id + '/' + category_id + '/' + jury_id,
        type: "GET",
        dataType: "json",
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    updateFavorite(list, favorite) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/jury/creation_categories/update-favorite',
        type: "POST",
        dataType: "json",
        data: {
          creation_id: list.get('creation.id'),
          category_id: list.get('category.id'),
          favorite: favorite,
          jury_id: this.get('jury.id')
        },
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    updateShortlist(list, shortlist) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/jury/creation_categories/update-shortlist',
        type: "POST",
        dataType: "json",
        data: {
          creation_id: list.get('creation.id'),
          category_id: list.get('category.id'),
          shortlist: shortlist,
          jury_id: this.get('jury.id')
        },
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    updateNomination(list, nomination) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/jury/creation_categories/update-nomination',
        type: "POST",
        dataType: "json",
        data: {
          creation_id: list.get('creation.id'),
          category_id: list.get('category.id'),
          nomination: nomination,
          jury_id: this.get('jury.id')
        },
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    updateMention(list, mention) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/jury/creation_categories/update-mention',
        type: "POST",
        dataType: "json",
        data: {
          creation_id: list.get('creation.id'),
          category_id: list.get('category.id'),
          mention: mention,
          jury_id: this.get('jury.id')
        },
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    resetFilter() {
      this.set('search', null);
      this.set('selectedCategory', null);
      this.set('selectedSortType', null);
      this.set('showFavorite', false);
    }

  });

  _exports.default = _default;
});
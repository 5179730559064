define("da/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function (key) {
      // From http://emberjs.com/api/classes/Ember.String.html#method_underscore
      return Ember.String.underscore(key);
    }
  });

  _exports.default = _default;
});
define("da/helpers/command-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.commandPrice = commandPrice;
  _exports.default = void 0;

  function commandPrice([that, campagne, category]) {
    var total = that.get('total');
    category = campagne.get('categories').find(function (value) {
      return value.id === category.id;
    });
    var price = category.get('pu') * campagne.get('nbCreations');

    if (price > category.get('px3') && category.get('px3') > 0) {
      price = category.get('px3');
    }

    if (campagne.get('nbCreation') > 2 && category.get('px3') > 0) {
      price = category.get('px3');
    }

    that.set('total', total + price);
    return price;
  }

  var _default = Ember.Helper.helper(commandPrice);

  _exports.default = _default;
});
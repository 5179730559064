define("da/routes/wait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      $.ajax({
        url: 'http://localhost:7000/api/v1/adhesion/2/1',
        type: "POST",
        dataType: "json",
        data: {
          current_period_start: '2019-01-16'
        }
      });
    }

  });

  _exports.default = _default;
});
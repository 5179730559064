define("da/components/jury/user-creation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['user-creation'],
    classNameBindings: ['isShortlist', 'isNominate', 'isMention'],
    store: Ember.inject.service('store'),
    voteService: Ember.inject.service('vote'),
    userCreation: Ember.computed('list.userCreation.isNew', function () {
      if (!this.get('list.userCreation.id')) {
        return this.get('store').createRecord('user-creation', {
          creationCategory: this.get('list'),
          creation: this.get('list.creation'),
          category: this.get('list.category'),
          jury: this.get('voteService.jury')
        });
      }

      var userCreation = this.get('store').peekRecord('user-creation', this.get('list.userCreation.id'));
      userCreation.setProperties({
        creationCategory: this.get('list'),
        creation: this.get('list.creation'),
        category: this.get('list.category'),
        jury: this.get('voteService.jury')
      });
      return this.get('store').peekRecord('user-creation', this.get('list.userCreation.id'));
    }),
    isShortlist: Ember.computed.alias('userCreation.shortlist'),
    isNominate: Ember.computed.alias('userCreation.nomination'),
    isMention: Ember.computed.alias('userCreation.mention'),
    actions: {
      shortlist(model) {
        var shorlist = model.get('shortlist');

        if (shorlist) {
          model.setProperties({
            shortlist: false,
            nomination: false,
            mention: false,
            price: false
          });
        } else {
          model.set('shortlist', true);
        } //console.log(model);


        model.save();
      },

      nomination(model) {
        var nomination = model.get('nomination');

        if (nomination) {
          model.setProperties({
            nomination: false,
            mention: false,
            price: false
          });
        } else {
          model.set('nomination', true);
          model.set('shortlist', true);
        }

        model.save();
      },

      mention(model) {
        var mention = model.get('mention');

        if (mention) {
          model.setProperties({
            price: false,
            mention: false
          });
        } else {
          model.set('mention', true);
          model.set('nomination', true);
          model.set('shortlist', true);
        }

        model.save();
      }

    }
  });

  _exports.default = _default;
});
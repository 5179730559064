define("da/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f7VVjKOy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showDialog\"]]],null,{\"statements\":[[4,\"paper-dialog\",null,[[\"onClick\",\"clickOutsideToClose\"],[[28,\"action\",[[23,0,[]],\"closeDialog\"],null],true]],{\"statements\":[[4,\"paper-dialog-content\",null,null,{\"statements\":[[0,\"      \"],[7,\"h2\",true],[10,\"class\",\"md-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[22,\"message\"],true],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-dialog-actions\",null,[[\"class\"],[\"layout-row\"]],{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"question\"]]],null,{\"statements\":[[4,\"paper-button\",null,[[\"primary\",\"accent\",\"onClick\"],[true,true,[28,\"action\",[[23,0,[]],[24,[\"actionOnAccept\"]],[24,[\"params\"]]],null]]],{\"statements\":[[0,\"          \"],[1,[22,\"labelYes\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"paper-button\",null,[[\"primary\",\"accent\",\"onClick\"],[true,true,[28,\"action\",[[23,0,[]],[24,[\"actionOnRefuse\"]],[24,[\"params\"]]],null]]],{\"statements\":[[0,\"          \"],[1,[22,\"labelNo\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"paper-button\",null,[[\"primary\",\"accent\",\"onClick\"],[true,true,[28,\"action\",[[23,0,[]],[24,[\"actionOnAccept\"]],[24,[\"params\"]]],null]]],{\"statements\":[[0,\"          Ok !\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\\n\\n\"],[4,\"if\",[[24,[\"currentlyLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"id\",\"loader\"],[8],[0,\"\\n    \"],[1,[28,\"paper-progress-linear\",null,[[\"accent\"],[true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/application.hbs"
    }
  });

  _exports.default = _default;
});
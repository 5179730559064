define("da/components/vimeo-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    vimeoService: Ember.inject.service('vimeo'),
    publish: true,
    vimeo: Ember.computed.alias('video.vimeo'),

    init() {
      this._super();

      this.get('loadVimeo');
    },

    loadVimeo: Ember.observer('video.vimeoPath', function () {
      var id = this.get('video.vimeoPath').split('/')[2];
      this.set('id', id);
      let video = this.get('video');
      this.loadVideo().then(vimeo => {
        if (vimeo.get('status') != "available") {
          this.set('publish', false);
          Ember.run.later(this, function () {
            this.loadVimeo();
          }, 30000);
        } else {
          this.set('video.vimeo', vimeo);

          if (video.get('videoType') === 'small' && vimeo.get('duration') >= 40) {
            video.destroyRecord().then(video => {
              this.set('dialog.show', true);
              this.set('dialog.title', 'Erreur');
              this.set('dialog.message', 'La vidéo dure plus de 40 secondes');
            });
          }

          if (video.get('videoType') === 'normal' && Ember.isEmpty(video.get('creation.cover'))) {
            this.get('vimeoService').prev(vimeo.get('prev'), video.get('creation.id')).then(image => {
              this.get('store').pushPayload(image);
            });
          }

          this.set('publish', true);
        }
      });
    }).on('init'),
    loadVideo: function () {
      return this.get('store').findRecord('vimeo-video', this.get('id'));
    },
    player: Ember.computed('vimeo.player_embed_url', function () {
      return '<iframe src="' + this.get('vimeo.player_embed_url') + '" width="1280" height="720" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen> </iframe>';
    }),
    couldUploadSmallVideo: Ember.computed('vimeo.duration', 'video.videoType', 'publish', function () {
      return this.get('video.videoType') === 'normal' && this.get('vimeo.duration') >= 40 && this.get('publish');
    }),
    actions: {
      close: function () {}
    }
  });

  _exports.default = _default;
});
define("da/controllers/juries/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    store: Ember.inject.service('store'),
    sortedJuries: Ember.computed.sort('model.juries', 'sortProperties'),
    sortProperties: ['categoryParent.libelle']
  });

  _exports.default = _default;
});
define("da/controllers/campagne/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    isStudent: Ember.computed('model.userApi.special', function () {
      return this.get('model.userApi.special') === 'student';
    }),
    structureTitle: Ember.computed('isStudent', function () {
      if (this.get('isStudent')) {
        return 'École';
      }

      return 'Structure / Société';
    }),
    structureLabel: Ember.computed('isStudent', function () {
      if (this.get('isStudent')) {
        return 'Nom de l’école';
      }

      return 'Nom de la structure';
    }),
    actions: {
      update: function (model) {
        model.save().then(model => {
          this.get('dialog').setProperties({
            show: true,
            title: 'Modification des informations du compte',
            message: 'Les modifications de votre compte ont bien été enregistrées'
          });
        }); //this.transitionToRoute('campagne.index');
      }
    }
  });

  _exports.default = _default;
});
define("da/routes/campagne/edit/creation/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      this.controllerFor('campagne.edit').set('currentRoute', this.get('routeName'));
    }

  });

  _exports.default = _default;
});
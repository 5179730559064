define("da/models/vimeo-video", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    duration: _emberData.default.attr('number'),
    link: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    width: _emberData.default.attr('string'),
    height: _emberData.default.attr('string'),
    pictures: _emberData.default.attr('object'),
    download: _emberData.default.attr('object'),
    embed: _emberData.default.attr('object'),
    player_embed_url: _emberData.default.attr('string'),
    sizes: Ember.computed('pictures', function () {
      return Ember.ArrayProxy.create({
        content: this.get('pictures.sizes')
      });
    }),
    downloadLink: Ember.computed('download', function () {
      var data = Ember.ArrayProxy.create({
        content: this.get('download')
      });
      var download = data.find(function (download) {
        return download.quality === 'hd';
      });

      if (!download) {
        download = data.get('firstObject');
      }

      return download.link;
    }),
    prev: Ember.computed('sizes.length', function () {
      let image = this.get('sizes').find(function (thumb, index) {
        return thumb.width >= 1024 && thumb.height >= 768;
      });

      if (!image) {
        return false;
      }

      return image.link;
    })
  });

  _exports.default = _default;
});
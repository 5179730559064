define("da/models/user-api", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    //company
    role: _emberData.default.attr('string'),
    agencyName: _emberData.default.attr('string'),
    structure: _emberData.default.belongsTo('user-category'),
    selectedcategories: _emberData.default.attr(),
    category: _emberData.default.attr(),
    address: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    presentation: _emberData.default.attr('string'),
    imageThumb: _emberData.default.attr('string'),
    special: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    //inscription
    firstname: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    //Responsable pédagogique
    headName: _emberData.default.attr('string'),
    headFirstname: _emberData.default.attr('string'),
    headEmail: _emberData.default.attr('string'),
    headPhone: _emberData.default.attr('string'),
    //facturation
    billingName: _emberData.default.attr('string'),
    billingFirstname: _emberData.default.attr('string'),
    billingEmail: _emberData.default.attr('string'),
    billingPhone: _emberData.default.attr('string'),
    siren: _emberData.default.attr('string'),
    nbShortlist: _emberData.default.attr('number'),
    nbCampagne: _emberData.default.attr('number'),
    userCommands: _emberData.default.hasMany('user-command'),
    votes: _emberData.default.hasMany('vote', {
      async: true
    }),
    canAccessVotes: Ember.computed('role', function () {
      return this.get('isAdmin') || this.get('role') === 'judge' ? true : false;
    }),
    canAccessJury: Ember.computed('role', function () {
      return this.get('isAdmin') || this.get('isSupervisor') || this.get('role') === 'judge' ? true : false;
    }),
    isAdmin: Ember.computed('role', function () {
      return this.get('role') === 'admin' ? true : false;
    }),
    isSupervisor: Ember.computed('role', function () {
      return this.get('role') === 'supervisor' ? true : false;
    }),
    fullname: Ember.computed('firstname', 'name', function () {
      return this.get('name') + ' ' + this.get('firstname');
    }),
    hasVote: Ember.computed('votes.length', function () {
      return this.get('votes.length') > 0 ? true : false;
    }),
    isStudent: Ember.computed('special', {
      get() {
        let special = this.get('special');
        return special === 'student' ? true : false;
      },

      set(key, value) {
        if (value) {
          this.set('special', 'student');
          this.set('structure', {
            'id': 16
          });
          return value;
        }

        this.set('special', '');
        return value;
      }

    }) // structures: A([
    //   'Agence',
    //   'Agence média',
    //   'Annonceur',
    //   'Créatif',
    //   'Chaine',
    //   'Ecole',
    //   'Maison de production',
    //   'Studio',
    // ]),

  });

  _exports.default = _default;
});
define("da/components/upload-ui/button-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    file: '',
    attributeBindings: ['name'],
    classNames: ['droppable-area'],

    dragOver(event) {
      event.preventDefault();
    },

    drop(event) {
      event.preventDefault(); // Get the dragged data (element ID)

      const files = event.dataTransfer.files;
      const maxFile = parseInt(this.get('maxFile'));

      if (maxFile === 8) {
        const numbersToIterate = Array(7).fill().map((_, index) => index);
        numbersToIterate.forEach(number => {
          if (files[number]) {
            this.set('file', files[number]);
            this.get('validate')();
          }
        });
      } else {
        this.set('file', files[0]);
        this.get('validate')();
      } // Do something with the dropped data

    },

    click() {
      var file = this.$().parent().find('input');
      file.click();
    }

  });

  _exports.default = _default;
});
define("da/controllers/campagne/edit/creation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dialog: Ember.inject.service(),
    warningShowed: false,
    competition: Ember.computed(function () {
      return this.get('store').peekAll('competition').get('firstObject');
    }),
    forbiddenTypes: Ember.computed('model.campagne.categoryParents.length', function () {
      var data = [];
      this.get('model.campagne.categoryParents').forEach(function (category) {
        data = data.concat(category.get('forbiddenTypes'));
      });
      return data.uniq();
    }),
    types: Ember.computed.intersect('creationType', 'forbiddenTypes'),
    hasWarning: Ember.computed('types.length', 'warningShowed', function () {
      return this.get('warningShowed') === false && this.get('types.length') > 0 ? true : false;
    }),
    creationType: Ember.computed('model.creation.creationType', function () {
      return [this.get('model.creation.creationType')];
    }),
    showWarning: function () {
      this.set('warningShowed', true);
      this.get('dialog').setProperties({
        show: true,
        title: 'Attention',
        message: 'Vous inscrivez une création de type ' + this.get('types').join() + ' dans les catégories : ' + this.get('model.campagne.libelleCategoryParents'),
        question: true,
        labelYes: 'je sais ce que je fais',
        labelNo: 'Annuler',
        actionOnAccept: this.get('actions.create'),
        params: {
          creation: this.get('model.creation'),
          controller: this
        }
      });
    },
    actions: {
      confirmation(creation) {
        if (this.get('hasWarning')) {
          return this.showWarning();
        }

        return this.send('create', {
          creation: creation,
          controller: this
        });
      },

      create(params) {
        let controller = params.controller;
        this.get('dialog').reset();
        var creation = params.creation;
        creation.save().then(creation => {
          controller.set('model.creation', controller.get('store').createRecord('creation', {
            campagne: controller.get('model.campagne')
          }));
          controller.set('warningShowed', false);
          controller.transitionToRoute('campagne.edit.creation.' + creation.get('creationType'), controller.get('model.campagne.id'), creation.id);
        });
      },

      updateCreation(creation) {
        if (creation.get('hasDirtyAttributes')) {
          creation.save();
        }
      },

      show(campagne_id, creation) {
        this.transitionToRoute(creation.get('url'), campagne_id, creation.id);
      }

    }
  });

  _exports.default = _default;
});
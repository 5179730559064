define("da/controllers/campagne/edit/synopsis", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    competition: Ember.computed(function () {
      return this.get('store').peekAll('competition').get('firstObject');
    }),
    selectedCampagne: Ember.computed.alias('model.selectedCampagne'),
    campagnesCount: Ember.computed.alias('model.campagnes.length'),
    importProcessing: false,
    hasCampagnes: Ember.computed('campagnesCount', function () {
      return this.get('campagnesCount') > 0 ? true : false;
    }),
    actions: {
      refresh() {
        window.location.reload(true);
      },

      update(campagne) {
        if (campagne.get('hasDirtyAttributes')) {
          campagne.save();
        }
      },

      getSynopsis(campagne) {
        this.set('importProcessing', true);
        return $.ajax({
          url: _environment.default.host + '/api/v1/synopsis/' + this.get('model.campagne.id'),
          type: "POST",
          dataType: "json",
          data: {
            campagne_id: campagne.id
          },
          headers: {
            Authorization: this.get('auth').authorization()
          }
        }).then(campagne => {
          this.get('store').pushPayload(campagne);
          this.get('dialog').setProperties({
            show: true,
            title: 'Import réussi',
            message: 'Le synopsis a été copié',
            actionOnAccept: this.get('actions.refresh')
          });
          this.set('importProcessing', false);
        });
      }

    }
  });

  _exports.default = _default;
});
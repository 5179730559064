define("da/controllers/campagne/edit/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    categories: Ember.computed.filterBy('model.categories', 'isOpen', true),
    competition: Ember.computed(function () {
      return this.get('store').peekAll('competition').get('firstObject');
    }) //sortedCategories: sort('categories', 'categoriesSort'),
    //categoriesSort: ['ordre:asc'],
    //ranks: groupBy('categories', 'rankgrid'),

  });

  _exports.default = _default;
});
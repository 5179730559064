define("da/controllers/campagne/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service('auth'),
    user: Ember.computed.alias('auth.user'),
    onChangeSector: Ember.observer('model.sector.id', function () {
      var id = this.get('model.sector.id');

      if (id != undefined) {
        this.get('model').save();
      }
    }),
    showCategories: Ember.computed('user.special', 'model.nbCategories', function () {
      // pour les concours étudiant et futurs désirables, une seule catégorie existe et est pré-saisie
      // dans ce cas, et après avoir vérifié qu'ily a bien une catégorie, on affiche pas l'onglet Catégories
      if (this.get('user.special') === 'student' || this.get('user.special') === 'concours') {
        if (this.get('model.nbCategories') === 1) {
          return false;
        }
      }

      return true;
    }),
    creationsTabTitle: Ember.computed('model.nbCreations', function () {
      if (this.get('model.nbCreations') > 1) {
        return 'Créations';
      }

      return 'Création';
    }),
    categoriesTabTitle: Ember.computed('model.nbCategories', function () {
      if (this.get('model.nbCategories') > 1) {
        return 'Catégories';
      }

      return 'Catégorie';
    }),
    creditsTabTitle: Ember.computed('model.selectedCredits.length', function () {
      if (this.get('model.selectedCredits.length') > 1) {
        return 'Crédits';
      }

      return 'Crédit';
    }),
    annonceurLabel: Ember.computed('user.special', function () {
      if (this.get('user.special') === 'student') {// return 'École';
      }

      if (this.get('user.special') === 'concours') {//return 'Annonceur';
      }

      return 'Annonceur';
    }),
    actions: {
      showCredits(campagne) {
        if (campagne.get('nbCategories') <= 0) {
          this.get('dialog').setProperties({
            show: true,
            title: 'Séléctionner d’abord une catégorie',
            message: 'Vous devez choisir au moins une catégorie dans laquelle inscrire cette campagne avant de saisir les crédits. Merci !'
          });
          return this.transitionToRoute('campagne.edit.categories');
        }

        return this.transitionToRoute('campagne.edit.credits');
      },

      update(campagne) {
        if (campagne.get('hasDirtyAttributes')) {
          campagne.save();
        }
      },

      ready(campagne) {
        campagne.set('error', Ember.A());

        if (campagne.get('ready')) {
          campagne.set('ready', false);
          return campagne.save();
        }

        return this.get('store').findAll('creation').then(creations => {
          var errors = campagne.canReady();

          if (errors.get('length') <= 0) {
            campagne.set('ready', true);
            return campagne.save();
          }

          return this.get('dialog').setProperties({
            show: true,
            title: 'Votre inscription n’est pas encore prête…',
            message: errors.errorHasText
          });
        });
      },

      confirmation(campagne) {
        this.get('dialog').setProperties({
          show: true,
          title: 'Supression de l\'inscription',
          message: '<p>Les créations et les crédits seront également supprimés.</p><p><b>Voulez-vous vraiment supprimer cette inscription ?</b></p>',
          actionOnAccept: this.get('actions.delete'),
          question: true,
          params: {
            campagne
          }
        });
      },

      delete({
        campagne
      }) {
        campagne.destroyRecord().then(campagne => {
          this.get('dialog').reset();
          this.transitionToRoute('campagne.index');
        });
      },

      date(date) {
        this.set('date', date);
        this.set('model.datePublication', date);
      }

    }
  });

  _exports.default = _default;
});
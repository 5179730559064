define("da/transforms/publication", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize(deserialize) {
      return deserialize;
    },

    serialize(serialize) {
      return (0, _moment.default)(serialize).format('DD/MM/YYYY');
    }

  });

  _exports.default = _default;
});
define("da/components/user/user-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flex-100', 'flex-gt-xs-50', 'flex-gt-sm-33', 'gutter', 'animated', 'creation-category', 'user-item'],
    classNameBindings: ['fadeIn:fadeIn', 'active'],
    fadeIn: true,
    active: false,

    click() {
      var user = this.get('list');
      this.set('active', true);
      this.get('login')(user);
    },

    willDestroyElement() {
      var clone = this.$().clone();
      this.$().parent().append(clone);
      clone.toggleClass('fadeOut').delay(500).queue(() => {
        clone.remove();
      });
    }

  });

  _exports.default = _default;
});
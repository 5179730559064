define("da/serializers/credit", ["exports", "da/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    keyForRelationship(key, typeClass, method) {
      if (key === "userCategory") {
        return "user_category";
      }

      return Ember.String.dasherize(key);
    },

    serialize(snapshot, options) {
      var json = this._super(...arguments);

      if (!Ember.isEmpty(json.data.relationships) && !Ember.isEmpty(json.data.relationships.campagne)) {
        json.data.attributes['campagne_id'] = json.data.relationships.campagne.data.id;
      }

      if (!Ember.isEmpty(json.data.relationships) && !Ember.isEmpty(json.data.relationships.quality)) {
        json.data.attributes['idqualite'] = json.data.relationships.quality.data.id;
      }

      if (!Ember.isEmpty(json.data.relationships) && !Ember.isEmpty(json.data.relationships['user_category'])) {
        json.data.attributes['user_category_id'] = json.data.relationships['user_category'].data.id;
      }

      return json;
    }

  });

  _exports.default = _default;
});
define("da/controllers/campagne/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    campagnes: Ember.computed.alias('model.campagnes'),
    campagne: Ember.computed.alias('model.campagne'),
    sectors: Ember.computed.alias('model.sectors'),
    store: Ember.inject.service(),
    application: Ember.inject.controller(),
    readyCampagnes: Ember.computed.mapBy('availableCampagnes', 'ready'),
    auth: Ember.inject.service('auth'),
    user: Ember.computed.alias('auth.user'),
    competition: Ember.computed(function () {
      return this.get('store').peekAll('competition').get('firstObject');
    }),
    // 2024 : CDA want users to do the validation during the competition, so we don't check anymore if the competition is over
    canAccessValidation: Ember.computed(
    /*'competition.isOver', */
    'user.nbShortlist', function () {
      return this.get('user.nbShortlist') > 0; //return this.get('competition.isOver') && this.get('user.nbShortlist') > 0;
    }),
    availableCampagnes: Ember.computed('campagnes.length', 'campagnes.@each.isNew', function () {
      var campagnes = this.get('campagnes').filter(function (campagne) {
        return !campagne.get('isNew');
      });
      return campagnes;
    }),
    hasReadyCampagne: Ember.computed('readyCampagnes.length', function () {
      return this.get('readyCampagnes').includes(true);
    }),
    annonceurLabel: Ember.computed('user.special', function () {
      if (this.get('user.special') === 'student') {
        return 'École';
      }

      return 'Annonceur';
    }),
    dynamicTitle: Ember.computed('availableCampagnes.length', function (v) {
      var n = this.get('availableCampagnes.length');

      if (n > 1) {
        return "Mes " + n + " inscriptions";
      }

      return "Mes inscriptions";
    }),
    actions: {
      route: function (path) {
        this.transitionToRoute(path);
      },

      create(campagne) {
        campagne.save().then(campagne => {
          this.transitionToRoute('campagne.edit.index', campagne.id);
        });
      },

      update(campagne) {
        campagne.set('error', Ember.A());

        if (campagne.get('ready')) {
          campagne.set('ready', false);
          return campagne.save();
        }

        return this.get('store').findAll('creation').then(creations => {
          var errors = campagne.canReady();

          if (errors.get('length') <= 0) {
            campagne.set('ready', true);
            return campagne.save();
          }

          return this.get('dialog').setProperties({
            show: true,
            title: 'Votre inscription n\'est pas prête',
            message: errors.errorHasText
          });
        });
      },

      show(campagne) {
        this.transitionToRoute('campagne.edit.index', campagne.id);
      }

    }
  });

  _exports.default = _default;
});
define("da/routes/campagne/command", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    command: Ember.inject.service(),
    model: function () {
      return this.get('auth').currentUser().then(user => {
        this.store.pushPayload(user);
        var id = this.get('auth.loggedHas') ? this.get('auth.loggedHas.id') : user.data.id;
        return this.get('command').getCommand(id).then(command => {
          return Ember.RSVP.hash({
            command: command,
            user: this.store.peekRecord('user-api', id)
          });
        }, errors => {
          console.log(errors);
        });
      });
    }
  });

  _exports.default = _default;
});
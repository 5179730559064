define("da/controllers/juries/auth/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    competition: Ember.computed(function () {
      return this.get('store').peekAll('competition').get('firstObject');
    }),
    actions: {
      login(model) {
        this.get('auth').login(model.get('email'), model.get('password')).then(response => {
          this.get('auth').setToken(response.access_token, response.expire);
          this.transitionToRoute('juries');
        }, error => {
          this.set('dialog.show', true);
          this.set('dialog.title', 'Erreur');
          this.set('dialog.message', 'L\'email ou le mot de passe n\'est pas correcte');
        });
      }

    }
  });

  _exports.default = _default;
});
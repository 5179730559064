define("da/models/credit", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  var _default = _emberData.default.Model.extend({
    nom: _emberData.default.attr('string'),
    prenom: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    structureName: _emberData.default.attr('string'),
    structure: _emberData.default.attr('string'),
    campagne: _emberData.default.belongsTo('campagne'),
    quality: _emberData.default.belongsTo('quality'),
    userCategory: _emberData.default.belongsTo('user-category'),
    userCategoryId: _emberData.default.attr('string'),
    updated: false,
    order: _emberData.default.attr('number'),
    qualityName: _emberData.default.attr('string'),
    onChange: Ember.observer('quality.id', function () {
      if (!this.get('isNew') && this.get('quality.id')) {
        this.save();
      }
    }),
    userCategoryObserver: Ember.observer('userCategory', function () {
      if (this.get('isDeleted') === false) {
        this.set('userCategoryId', this.get('userCategory.id'));
      }
    }),
    isValid: Ember.computed('nom', 'prenom', function () {
      return !this.isNew && !Ember.isEmpty(this.get('nom')) && !Ember.isEmpty(this.get('prenom'));
    }),
    isValidEmail: Ember.computed('nom', 'prenom', 'email', function () {
      return !Ember.isEmpty(this.get('email')) && validateEmail(this.get('email'));
    }),
    isValidWithEmail: Ember.computed('nom', 'prenom', 'email', function () {
      return this.get('isValid') && this.get('isValidEmail');
    })
  });

  _exports.default = _default;
});
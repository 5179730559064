define("da/controllers/campagne/command", ["exports", "ember-group-by", "moment", "da/config/environment"], function (_exports, _emberGroupBy, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    categories: (0, _emberGroupBy.default)('model.command'),
    totalArray: Ember.computed.mapBy('model.command', 'total'),
    total: 0,
    auth: Ember.inject.service(),
    store: Ember.inject.service(),
    command: Ember.inject.service(),
    date: Ember.computed(function () {
      return (0, _moment.default)(new Date()).format('DD/MM/YYYY');
    }),
    hasReadyCompetition: Ember.computed('model.user.userCommands.length', function () {
      return this.get('model.user.userCommands.length') > 0 && this.get('model.user.nbCampagne') > 0 ? true : false;
    }),
    actions: {
      print() {
        return window.print();
      },

      competitionReady() {
        return $.ajax({
          url: _environment.default.host + '/api/v1/auth/ready_competition',
          type: "POST",
          headers: {
            Authorization: this.get('auth').authorization(),
            LoggedHas: this.get('auth.loggedHas.id')
          }
        }).then(result => {
          this.get('store').pushPayload('user-command', result);
        });
      },

      pdf() {
        let id = this.get('model.user.id');
        return this.get('command').getCommandPdf(id).then(url => {
          return window.open(url, '_blank');
        });
      }

    }
  });

  _exports.default = _default;
});
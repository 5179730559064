define("da/routes/palmares", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    palmares: Ember.inject.service(),

    beforeModel(transition) {
      if (transition.to.queryParams.category) {
        this.set('palmares.category', transition.to.queryParams.category);
      }

      return this.get('palmares').setup();
    },

    model() {
      var competition = this.store.peekAll('competition').get('firstObject');
      return Ember.RSVP.hash({
        competition: competition
      });
    }

  });

  _exports.default = _default;
});
define("da/services/auth", ["exports", "da/config/environment", "js-cookie"], function (_exports, _environment, _jsCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    setup() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.currentUser().then(res => {
          this.get('store').pushPayload(res);

          if (this.get('loggedHas.id')) {
            this.set('user', this.get('store').peekRecord('user-api', this.get('loggedHas.id')));
          } else {
            this.set('user', this.get('store').peekRecord('user-api', res.data.id));
          }

          resolve(res.data);
        }, error => {
          reject(error);
        });
      });
    },

    login(email, password) {
      return Ember.$.ajax({
        url: _environment.default.host + '/oauth/token',
        type: "POST",
        data: {
          grant_type: 'password',
          client_id: _environment.default.auth.client_id,
          client_secret: _environment.default.auth.client_secret,
          password: password,
          username: email
        },
        dataType: "json"
      });
    },

    register(model) {
      let params = model.serialize();
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/auth/register',
        type: "POST",
        data: params.data.attributes,
        dataType: "json"
      });
    },

    currentUser() {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/auth/user',
        type: "GET",
        dataType: "json",
        headers: {
          Authorization: this.authorization()
        }
      });
    },

    updatetUser(params) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/auth/update',
        type: "PUT",
        data: params,
        dataType: "json",
        headers: {
          Authorization: this.authorization()
        }
      });
    },

    forget(email) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/auth/forget',
        type: "POST",
        data: {
          email: email
        },
        dataType: "json"
      });
    },

    reset(data) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/auth/reset',
        type: "POST",
        data: data,
        dataType: "json"
      });
    },

    setToken(token, expire) {
      _jsCookie.default.set('token', token);

      _jsCookie.default.set('expire', expire);

      this.set('token', token);
    },

    getCookieToken() {
      return _jsCookie.default.get('token');
    },

    logout() {
      return _jsCookie.default.remove('token');
    },

    authorization() {
      return 'Bearer ' + _jsCookie.default.get('token');
    },

    getUser: Ember.computed('loggedHas.id', function () {
      return !Ember.isEmpty(this.get('loggedHas')) ? this.get('loggedHas') : this.get('user');
    }),
    fullname: Ember.computed('user.{firstname,lastname}', 'loggedHas.id', function () {
      var user = !Ember.isEmpty(this.get('loggedHas')) ? this.get('loggedHas') : this.get('user');
      return user.get('firstname') + ' ' + user.get('name');
    })
  });

  _exports.default = _default;
});
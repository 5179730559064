define("da/routes/application", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    UnauthorizedError
  } = _emberData.default;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),

    beforeModel() {
      _moment.default.locale('fr');
    },

    model: function () {
      return this.get('store').queryRecord('competition', {});
    },
    actions: {
      didTransition(transition) {
        if (typeof gtag != 'function') {
          return;
        }

        var url = this.get('_router.url');

        if (url === '/') {
          url = '/home-competition';
        }

        return gtag('event', 'UA-140161034-1', {
          'page_path': url
        });
      },

      loading(transition, route) {
        let controller = this.controllerFor('application');
        controller.set('currentlyLoading', true);
        $('body').toggleClass('loading');
        transition.finally(function () {
          controller.set('currentlyLoading', false);
          $('body').toggleClass('loading');
        });
      },

      error: function (error) {
        if (error instanceof UnauthorizedError || error.status === 401 || Ember.isEmpty(this.get('auth').getCookieToken())) {
          this.transitionTo('auth.login');
          return;
        }
      }
    }
  });

  _exports.default = _default;
});
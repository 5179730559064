define("da/controllers/campagne/validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const removeSpecial = email => {
    // remove accent and special chars
    return email.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  var _default = Ember.Controller.extend({
    campagnes: Ember.computed.alias('model.campagnes'),
    applicationController: Ember.inject.controller('application'),
    duration: 4000,
    paperToaster: Ember.inject.service('paperToaster'),
    //hasError: computed('model.campagnes.@each.cantValidateCeremony', function() {}),
    actions: {
      updateCeremony(ceremony) {
        this.get('applicationController').set('currentlyLoading', true);
        let that = this; // Ember.run.later(function() {

        Ember.run.later(function () {
          ceremony.save().then(function () {
            that.get('applicationController').set('currentlyLoading', false);
          });
        }, 500);
      },

      updateCredit(credit) {
        this.get('applicationController').set('currentlyLoading', true);
        credit.set('email', removeSpecial(credit.get('email')));

        if (!validateEmail(credit.get('email'))) {
          this.get('paperToaster').show('L’email ' + credit.get('email') + ' est invalide.', {
            duration: 4000,
            position: 'bottom left',
            accent: true
          });
          credit.set('email', '');
        }

        if (!credit.get('nom') || !credit.get('prenom')) {
          this.get('paperToaster').show('Nom et prénom sont des champs obligatoires', {
            duration: 4000,
            position: 'bottom left',
            accent: true
          });
          return false;
        }

        let that = this; // Ember.run.later(function() {

        Ember.run.later(function () {
          credit.save().then(function () {
            that.get('applicationController').set('currentlyLoading', false);
          });
        }, 500);
      },

      redirectToCampagne(campagne) {
        campagne.set('validateCeremony', false);
        return this.transitionToRoute('campagne.edit', campagne);
      },

      ready(campagne) {
        if (!campagne.get('validateCeremony') && campagne.get('cantValidateCredit')) {
          this.get('paperToaster').show('Vous devez saisir les emails des personnes créditées pour nous permettre de les convier à la Cérémonie', {
            duration: 5000,
            position: 'bottom left',
            accent: true
          });
          return false;
        }
        /*if(campagne.get('cantValidateCeremony')) {
                  this.get('paperToaster').show('Vous devez saisir les personnes à appeler sur scène pour cette campagne', {
                      duration: 4000,
                      position: 'bottom left',
                      accent: true,
                  });
                   return false;
        }*/


        if (campagne.get('validateCeremony')) {
          campagne.set('validateCeremony', false);
        } else {
          campagne.set('validateCeremony', true);
        }

        campagne.save();
      },

      showMessage() {
        this.get('paperToaster').show('Il n\'y a pas de vidéo pour cette campagne', {
          duration: 4000,
          position: 'bottom left',
          accent: true
        });
      }

    }
  });

  _exports.default = _default;
});
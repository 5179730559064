define("da/routes/auth/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      var token = params.token;
      return Ember.Object.create({
        token: token
      });
    }

  });

  _exports.default = _default;
});
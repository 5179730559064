define("da/routes/campagne/validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    competition: Ember.inject.service(),

    beforeModel() {},

    afterModel() {},

    model: function () {
      return this.get('competition').getValidation().then(results => {
        let campagnesIds = Ember.ArrayProxy.create({
          content: Ember.A(results.campagnes.data)
        }).mapBy('id');
        this.store.pushPayload(results.campagnes);
        this.store.pushPayload(results.user_creations);
        this.store.pushPayload(results.credits);
        this.store.pushPayload(results.images);
        this.store.pushPayload(results.ceremonies);
        return Ember.RSVP.hash({
          campagnes: this.get('competition').getCampagneValidation(campagnesIds)
        });
      });
    }
  });

  _exports.default = _default;
});
define("da/routes/campagne/edit/credits", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    competition: Ember.inject.service(),
    model: function () {
      let campagne = this.modelFor('campagne.edit');
      return Ember.RSVP.allSettled([this.store.findAll('quality'), this.store.findAll('user-category'), this.get('competition').getCampagnes(campagne, 'credits'), this.store.findRecord('campagne', campagne.get('id'), {
        reload: true
      })]).then(([qualities, userCategories, campagnes]) => {
        qualities = qualities.value;
        userCategories = userCategories.value;
        campagnes = Ember.ArrayProxy.create({
          content: Ember.A(campagnes.value.data)
        });
        let credit = this.store.createRecord('credit', {
          campagne: campagne,
          //quality: qualities.get('firstObject'),
          userCategory: userCategories.get('firstObject')
        });
        return Ember.RSVP.hash({
          campagne: campagne,
          credit: credit,
          qualities: qualities,
          userCategories: userCategories,
          campagnes: campagnes,
          selectedCampagne: campagnes.get('firstObject')
        });
      });
    },

    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      this.controllerFor('campagne.edit').set('currentRoute', this.get('routeName'));
    }

  });

  _exports.default = _default;
});
define("da/routes/campagne/edit/creation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      let data = this.paramsFor('campagne.edit');
      return this.store.query('image', data).then(images => {
        let campagne = this.modelFor('campagne.edit');
        campagne.reload();
        let creation = this.store.createRecord('creation', {
          campagne: campagne
        });
        return Ember.RSVP.hash({
          creation: creation,
          campagne: campagne
        });
      });
    }
  });

  _exports.default = _default;
});
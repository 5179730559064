define("da/templates/components/audio-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kdc82aP+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"p\",true],[10,\"class\",\"current\"],[8],[0,\"Fichier actuel : \"],[7,\"span\",true],[8],[1,[24,[\"audio\",\"label\"]],false],[9],[9],[0,\"\\n\"],[1,[22,\"renderAudio\"],true],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/audio-player.hbs"
    }
  });

  _exports.default = _default;
});
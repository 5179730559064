define("da/routes/juries/jury/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      if (!Ember.isEmpty(params.jury_id)) {
        return this.transitionTo('juries.jury.creations', params.jury_id);
      }

      return this.transitionTo('juries.toc');
      /*
      if(isEmpty(params.jury_id) && juries.get('length') > 0) {
          let juries = this.modelFor('juries.jury').juries;
          params.jury_id = juries.sortBy('categoryParent.libelle').get('firstObject.id');
      }else {
          this.get('dialog').setProperties({
              show: true,
              title: 'Revenez plus tard !!!',
              message: 'Il n\'y a pas encore de jury pour cette compétition'
           });
          return false;
      }
        */
    }
  });

  _exports.default = _default;
});
define("da/serializers/creation", ["exports", "da/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot, options) {
      var json = this._super(...arguments);

      if (!Ember.isEmpty(json.data.relationships) && !Ember.isEmpty(json.data.relationships.campagne)) {
        json.data.attributes['idcampagne'] = json.data.relationships.campagne.data.id;
      }

      return json;
    },

    keyForRelationship(key, typeClass, method) {
      if (key === 'userCreations') {
        return 'user_creations';
      }

      return Ember.String.dasherize(key);
    }

  });

  _exports.default = _default;
});
define("da/components/upload-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    file: null,
    uploadedByte: 0,
    upload: Ember.inject.service(),
    store: Ember.inject.service(),
    imageType: 'prev',
    media: 'image',

    progress(e) {
      this.set('uploadedByte', e.loaded);
      this.set('totalByte', e.total);
    },

    actions: {
      validate: function () {
        var file = this.get('file');
        var imageType = this.get('imageType');
        var progress = this.get('progress').bind(this);

        if (this.get('media') === 'audio') {
          this.get('upload').audio(file, progress, this.get('creation.id')).then(audio => {
            this.get('store').pushPayload(audio);
            this.get('creation.audios').pushObject(this.get('store').peekRecord('audio', audio.data.id));
            this.set('file', null);
            this.$().parent().find('.file').val('');
          }, fail => {
            this.set('dialog.show', true);
            this.set('dialog.title', 'Erreur');
            this.set('dialog.message', fail.responseJSON.errors.upload);
            this.set('file', null);
            this.$().parent().find('.file').val('');
          });
        } else if (this.get('media') === 'image') {
          this.get('upload').image(file, imageType, progress, this.get('creation.id')).then(image => {
            this.get('store').pushPayload(image); //this.get('creation.images').pushObject(this.get('store').peekRecord('image', image.data.id));

            this.set('file', null);
            this.$().parent().find('.file').val('');
          }, fail => {
            this.set('dialog.show', true);
            this.set('dialog.title', 'Erreur');
            this.set('dialog.message', fail.responseJSON.errors.upload);
            this.set('file', null);
            this.$().parent().find('.file').val('');
          });
        } else {
          this.get('upload').prev(file, imageType, progress, this.get('creation.id')).then(image => {
            this.get('store').pushPayload(image);
            this.get('creation.images').pushObject(this.get('store').peekRecord('image', image.data.id));
            this.set('file', null);
            this.$().parent().find('.file').val('');
          }, fail => {
            this.set('dialog.show', true);
            this.set('dialog.title', 'Erreur');
            this.set('dialog.message', fail.responseJSON.errors.upload);
            this.set('file', null);
            this.$().parent().find('.file').val('');
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("da/templates/components/creation/content-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bqmBP5PP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"component\",[\"creation/cover-item\"],[[\"back\"],[[24,[\"back\"]]]]],false],[0,\"\\n\"],[1,[28,\"component\",[[24,[\"template\"]]],[[\"model\",\"update\",\"delete\",\"deleteImage\",\"back\"],[[24,[\"model\"]],[24,[\"update\"]],[24,[\"delete\"]],[24,[\"deleteImage\"]],[24,[\"back\"]]]]],false],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/creation/content-item.hbs"
    }
  });

  _exports.default = _default;
});
define("da/controllers/campagne/edit/credits", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    dialog: Ember.inject.service(),
    competition: Ember.computed(function () {
      return this.get('store').peekAll('competition').get('firstObject');
    }),
    selectedCampagne: Ember.computed.alias('model.selectedCampagne'),
    campagnesCount: Ember.computed.alias('model.campagnes.length'),
    availableCreditsCount: Ember.computed.alias('model.campagne.availableCredits.length'),
    importProcessing: false,
    hasCampagnes: Ember.computed('campagnesCount', function () {
      return this.get('campagnesCount') > 0 ? true : false;
    }),
    hasSuggestion: Ember.computed('availableCreditsCount', function () {
      return this.get('availableCreditsCount') > 0 ? true : false;
    }),
    filteredqualities: Ember.computed('model.qualities.length', function () {
      let allowed = this.get('model.campagne.allowed');
      return this.get('model.qualities').filter(function (quality) {
        return allowed.includes(parseInt(quality.get('id')));
      });
    }),
    actions: {
      refresh() {
        window.location.reload(true);
      },

      create(credit) {
        var quality = credit.get('quality');
        credit.save().then(credit => {
          this.get('model.campagne.credits').pushObject(credit);
          this.set('model.credit', this.get('store').createRecord('credit', {
            campagne: this.get('model.campagne'),
            quality: quality
          }));
        });
      },

      getCredits(campagne) {
        this.set('importProcessing', true);
        return $.ajax({
          url: _environment.default.host + '/api/v1/credits/' + this.get('model.campagne.id'),
          type: "POST",
          dataType: "json",
          data: {
            campagne_id: campagne.id
          },
          headers: {
            Authorization: this.get('auth').authorization()
          }
        }).then(credits => {
          this.get('store').pushPayload(credits);
          this.get('dialog').setProperties({
            show: true,
            title: 'Import réussi',
            message: 'Les crédits ont été copiés.',
            actionOnAccept: this.get('actions.refresh')
          });
        });
      },

      update(credit) {
        if (!credit.get('nom') || !credit.get('prenom')) {
          this.get('dialog').setProperties({
            show: true,
            title: 'Impossible de valider votre demande',
            message: 'Les champs nom et prénom sont obligatoires '
          });
          return false;
        }

        if (!credit.get('userCategory.id')) {
          this.get('dialog').setProperties({
            show: true,
            title: 'Impossible de valider votre demande',
            message: 'Vous devez choisir un type de structure.'
          });
          return false;
        }

        credit.set('updated', true);
        credit.save().then(() => {
          credit.set('updated', false);
        });
      },

      delete(credit) {
        credit.destroyRecord();
      }

    }
  });

  _exports.default = _default;
});
define("da/templates/components/multicheckbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NEfnjgat",
    "block": "{\"symbols\":[\"checkbox\"],\"statements\":[[4,\"each\",[[24,[\"checkboxes\"]]],null,{\"statements\":[[0,\" \"],[5,\"paper-checkbox\",[],[[\"@value\",\"@onChange\"],[[23,1,[\"isChecked\"]],[28,\"action\",[[23,0,[]],\"update\",[23,1,[]]],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[\"label\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/multicheckbox.hbs"
    }
  });

  _exports.default = _default;
});
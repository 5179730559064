define("da/components/palmares/creation-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['campaigns', 'layout-row', 'layout-wrap', 'layout-align-start-start', 'animated'],
    classNameBindings: ['fadeIn:fadeIn'],
    fadeIn: true,
    nbDisplay: 12,
    couldTop: false,
    limitedLists: Ember.computed('lists.length', 'nbDisplay', function () {
      var nb = this.get('nbDisplay');
      return this.get('lists').slice(0, nb);
    }),

    didInsertElement() {
      this._super();

      Ember.$(window).on('scroll', e => {
        var scrollHeight = Ember.$(document).height();
        var scrollPosition = Ember.$(window).height() + Ember.$(window).scrollTop() + 50;
        this.set('currentScroll', Ember.$(window).scrollTop());

        if ((scrollHeight - scrollPosition) / scrollHeight <= 0) {
          this.loadContent();
        }

        this.set('couldTop', false);

        if (Ember.$(window).scrollTop() > 100) {
          this.set('couldTop', true);
        }

        if (Ember.$(window).scrollTop() <= 50) {
          this.set('nbDisplay', 12);
        }
      });
    },

    shouldLoad: Ember.computed('lists.length', 'limitedLists.length', function () {
      return this.get('lists.length') != this.get('limitedLists.length');
    }),

    loadContent() {
      if (this.get('nbDisplay') < this.get('lists.length')) {
        this.incrementProperty('nbDisplay', 12);
      }
    },

    actions: {
      scrollTop() {
        Ember.$('html, body').animate({
          scrollTop: 0
        }, 800);
        this.set('nbDisplay', 12);
      }

    },

    willDestroyElement() {
      Ember.$(window).off('scroll');
    }

  });

  _exports.default = _default;
});
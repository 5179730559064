define("da/services/jury", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),
    lists: Ember.computed('campagnes.length', function () {
      var data = Ember.A([]);
      var id = 0;
      this.get('campagnes').forEach(function (campagne) {
        campagne.get('categories').forEach(function (category) {
          campagne.get('creations').forEach(function (creation) {
            id = id + 1;
            data.pushObject(Ember.Object.create({
              id: id,
              category: category,
              creation: creation,
              campagne: campagne
            }));
          });
        });
      });
      return data;
    }),
    getArchive: function (jury_id) {
      return $.ajax({
        url: _environment.default.host + '/api/v1/jury/archive/' + jury_id,
        type: "GET",
        dataType: "json",
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    }
  });

  _exports.default = _default;
});
define("da/adapters/category-parent", ["exports", "da/adapters/application", "ember-inflector"], function (_exports, _application, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function (type) {
      return 'category_parents';
    }
  });

  _exports.default = _default;
});
define("da/routes/juries/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    model: function () {
      var competition = this.modelFor('application');
      return this.get('auth').currentUser().then(user => {
        this.store.pushPayload(user);
        return Ember.RSVP.hash({
          competition: competition,
          userApi: this.store.peekRecord('user-api', user.data.id)
        });
      });
    }
  });

  _exports.default = _default;
});
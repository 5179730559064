define("da/templates/components/palmares/image-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1CKZY2Vx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"img\",true],[11,\"src\",[29,[[22,\"image\"]]]],[11,\"alt\",[29,[[24,[\"creation\",\"libelle\"]]]]],[8],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/palmares/image-item.hbs"
    }
  });

  _exports.default = _default;
});
define("da/templates/components/palmares/nav-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qp8u1rzX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"paper-toolbar\",null,[[\"tall\",\"accent\"],[true,true]],{\"statements\":[[4,\"paper-toolbar-tools\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\" btn prev \",[22,\"disabledPrev\"]]]],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"prev\"],null]],[8],[0,\"\\n      \"],[1,[28,\"paper-icon\",[\"arrow-back\"],[[\"size\"],[40]]],false],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"Création\"],[7,\"br\",true],[8],[9],[0,\"précédente\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\" btn next \",[22,\"disabledNext\"]]]],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"next\"],null]],[8],[0,\"\\n      \"],[1,[28,\"paper-icon\",[\"arrow-forward\"],[[\"size\"],[40]]],false],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"Création\"],[7,\"br\",true],[8],[9],[0,\"suivante\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/palmares/nav-ui.hbs"
    }
  });

  _exports.default = _default;
});
define("da/controllers/auth/concours.deprecated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),

    login(email, password) {
      this.get('auth').login(email, password).then(response => {
        this.get('auth').setToken(response.access_token, response.expire);
        this.transitionToRoute('campagne.index');
      }, error => {
        this.transitionToRoute('login', error);
      });
    },

    actions: {
      register(model) {
        let password = model.get('password');
        let email = model.get('email');
        model.save().then(user => {
          this.login(email, password);
        });
      }

    }
  });

  _exports.default = _default;
});
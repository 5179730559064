define("da/templates/components/categories/menu-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oaGg8tBS",
    "block": "{\"symbols\":[\"categoryParent\",\"category\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"sortedItems\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"category category-c\",[23,1,[\"id\"]]]]],[11,\"style\",[29,[\"background: \",[23,1,[\"color\"]],\" !important\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"p\",false],[3,\"action\",[[23,0,[]],\"changeClass\",[23,1,[\"id\"]]]],[8],[0,\"\\n        \"],[1,[28,\"paper-icon\",[\"chevron-right\"],[[\"size\",\"class\"],[18,\"puce\"]]],false],[0,\"\\n        \"],[7,\"strong\",true],[8],[1,[23,1,[\"libelle\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"sub\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"categories\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"component\",[\"categories/children-ui\"],[[\"category\",\"campagne\"],[[23,2,[]],[24,[\"campagne\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/categories/menu-ui.hbs"
    }
  });

  _exports.default = _default;
});
define("da/templates/components/categories/categories-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MdgsXvno",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"category-item layout-row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"color flex-25\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"bg category-c\",[24,[\"category\",\"categoryParent\",\"id\"]]]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"category\",\"categoryParent\",\"isOpen\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"bt\"],[8],[0,\"\\n        \"],[1,[28,\"paper-icon\",[\"delete\"],[[\"size\",\"class\",\"accent\"],[\"20\",\"deleteIt\",true]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text flex-75\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"children-category\"],[8],[1,[24,[\"category\",\"libelle\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"parent-category\"],[8],[1,[24,[\"category\",\"categoryParent\",\"libelle\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/categories/categories-selected.hbs"
    }
  });

  _exports.default = _default;
});
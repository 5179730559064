define("da/models/jury", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    categoryParent: _emberData.default.belongsTo('category-parent'),
    name: _emberData.default.attr('string'),
    presidentId: _emberData.default.attr('string'),
    president: _emberData.default.belongsTo('user-api'),
    presidentIntro: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
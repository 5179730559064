define("da/components/jury/creation/video-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paperToaster: Ember.inject.service('paperToaster'),
    store: Ember.inject.service('store'),
    vimeoService: Ember.inject.service('vimeo'),
    classNames: ['creation-video', 'element'],
    video: Ember.computed('creation.videos.length', function () {
      return this.get('creation.videos').filterBy('videoType', 'normal').get('firstObject');
    }),
    vimeo: Ember.computed('video.id', function () {
      if (Ember.isEmpty(this.get('video'))) {
        this.get('paperToaster').show('Il n\'y a pas de vidéo pour cette campagne', {
          duration: 4000,
          position: 'top left',
          accent: true
        });
        return false;
      }

      var id = this.get('video.vimeoPath').split('/')[2];
      var vimeo = this.get('vimeoService').findRecord(id);
      return _emberData.default.PromiseObject.create({
        promise: vimeo
      }); //return '<iframe src="https://player.vimeo.com/video/'+id+'?autoplay=1&badge=0&autopause=0&player_id=0" width="1280" height="720" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen> </iframe>';
    })
  });

  _exports.default = _default;
});
define("da/models/category-parent", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    libelle: _emberData.default.attr('string'),
    rankgrid: _emberData.default.attr('string'),
    color: _emberData.default.attr('string'),
    color_text: _emberData.default.attr('string'),
    ordre: _emberData.default.attr('string'),
    categories: _emberData.default.hasMany('category'),
    campagnes: _emberData.default.hasMany('campagne'),
    juries: _emberData.default.hasMany('jury'),
    is_audio: _emberData.default.attr('boolean'),
    is_video: _emberData.default.attr('boolean'),
    is_image: _emberData.default.attr('boolean'),
    filteredCategories: Ember.computed('categories.length', function () {
      return this.get('categories').filter(function (category) {
        return category.get('campagnes.length') > 0;
      });
    }),
    priceCountArray: Ember.computed.mapBy('filteredCategories', 'total'),
    total: Ember.computed.sum('priceCountArray'),
    forbiddenTypes: Ember.computed(function () {
      var data = [];

      if (!this.get('is_audio')) {
        data.push('audio');
      }

      if (!this.get('is_video')) {
        data.push('video');
      }

      if (!this.get('is_image')) {
        data.push('image');
      }

      return data;
    }),
    isOpen: Ember.computed('juries.length', function () {
      if (this.get('juries.length') === 0) {
        return false;
      }

      return this.get('juries.firstObject.status') === 'open';
    })
  });

  _exports.default = _default;
});
define("da/templates/campagne/edit/creation/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZrCBysaT",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[\"creation/content-item\"],[[\"model\",\"template\",\"update\",\"delete\",\"back\"],[[24,[\"model\"]],\"creation/image-item\",[28,\"action\",[[23,0,[]],\"update\"],null],[28,\"action\",[[23,0,[]],\"confirmation\"],null],[28,\"action\",[[23,0,[]],\"back\"],null]]]],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/campagne/edit/creation/image.hbs"
    }
  });

  _exports.default = _default;
});
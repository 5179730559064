define("da/models/sector", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    libelle: _emberData.default.attr('string'),
    ordre: _emberData.default.attr('number'),
    status: Ember.A([{
      key: 'publish',
      label: 'Publié'
    }, {
      key: 'archive',
      label: 'Archive'
    }, {
      key: 'draft',
      label: 'Brouillon'
    }])
  });

  _exports.default = _default;
});
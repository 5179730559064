define("da/services/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    show: false,
    message: '',
    title: '',
    actionOnAccept: null,
    actionOnRefuse: null,
    labelYes: 'Oui',
    labelNo: 'Non',
    params: {},

    reset() {
      this.setProperties({
        show: false,
        message: '',
        title: '',
        actionOnAccept: null,
        actionOnRefuse: null,
        question: false,
        params: {}
      });
    }

  });

  _exports.default = _default;
});
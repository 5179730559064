define("da/controllers/campagne", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service('auth'),
    store: Ember.inject.service('store'),
    user: Ember.computed.alias('auth.user'),
    loggedHas: Ember.computed('auth.loggedHas', function () {
      if (Ember.isEmpty(this.get('auth.loggedHas'))) {
        return false;
      }

      return this.get('auth.user.id') === this.get('auth.loggedHas.id') ? false : true;
    }),
    // 2024 : CDA want users to do the validation during the competition, so we don't check anymore if the competition is over
    canAccessValidation: Ember.computed(
    /*'competition.isOver',*/
    'user.nbShortlist', function () {
      return this.get('user.nbShortlist') > 0; //return this.get('competition.isOver') && this.get('user.nbShortlist') > 0;
    }),
    competition: Ember.computed(function () {
      return this.get('store').peekAll('competition').get('firstObject');
    }),
    actions: {
      route: function (path) {
        this.transitionToRoute(path);
      },
      resetLoggedHas: function () {
        this.set('auth.loggedHas', this.get('auth.user'));
        this.get('store').unloadAll('campagne');
        this.send("sessionChanged");
        this.transitionToRoute('campagne.index');
      },
      logout: function () {
        this.get('auth').logout();
        this.get('store').unloadAll('campagne');
        this.transitionToRoute('auth.login');
      }
    }
  });

  _exports.default = _default;
});
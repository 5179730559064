define("da/controllers/juries/toc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    store: Ember.inject.service('store'),
    sortedJuries: Ember.computed.sort('model.juries', 'sortProperties'),
    sortProperties: ['categoryParent.ordre'],
    actions: {
      route: function (path) {
        if (path === 'campagne') {
          window.location = '/';
        }

        this.replaceRoute(path);
      },
      logout: function () {
        this.get('auth').logout();
        this.get('store').unloadAll('jury');
        this.transitionToRoute('juries.auth.login');
      }
    }
  });

  _exports.default = _default;
});
define("da/controllers/campagne/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    store: Ember.inject.service(),
    search: '',
    searchProperties: ['id', 'agencyName', 'name', 'firstname', 'email'],
    filteredUsers: Ember.computed('model.length', 'search', function () {
      var data = this.get('model');

      if (!Ember.isEmpty(this.get('search'))) {
        data = data.filter(item => {
          var objects = item.getProperties(this.get('searchProperties'));
          return this.checkMatch(objects);
        });
      }

      return data;
    }),

    checkMatch(objects) {
      var match = false;

      for (var key in objects) {
        if (objects[key].toLowerCase().indexOf(this.get('search').toLowerCase()) != -1) {
          match = true;
          return true;
        }
      }

      return match;
    },

    actions: {
      login(user) {
        this.get('auth').set('loggedHas', user);
        this.get('auth').set('user', user);
        this.get('store').unloadAll('campagne');
        this.transitionToRoute('campagne.index');
      }

    }
  });

  _exports.default = _default;
});
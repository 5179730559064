define("da/components/multicheckbox", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MultiSelectCheckbox = Ember.Object.extend({
    label: "label",
    value: "value",
    isChecked: false
  });

  var _default = Ember.Component.extend({
    // The list of available options.
    auth: Ember.inject.service(),
    checkboxes: Ember.computed("options", function () {
      var _this = this;

      var selected = this.get("selected");
      return this.get("options").map(function (opt) {
        var isChecked = false;

        if (selected) {
          isChecked = selected.includes(parseInt(opt.get('id')));
        }

        return MultiSelectCheckbox.create({
          label: opt.get('label'),
          value: opt.get('id'),
          isChecked: isChecked,
          changeValue: function (fn, value) {
            _this.get("selected")[fn](value);
          }
        });
      });
    }),
    actions: {
      update(checkbox) {
        if (checkbox.get('isChecked')) {
          checkbox.set('isChecked', false);
        } else {
          checkbox.set('isChecked', true);
        }

        var selected = this.get('checkboxes').filter(checkbox => {
          return checkbox.get('isChecked');
        }).map(c => c.get('value'));
        $.ajax({
          url: _environment.default.host + '/api/structure_type',
          type: "POST",
          dataType: "json",
          data: {
            categories: selected
          },
          headers: {
            Authorization: this.get('auth').authorization(),
            LoggedHas: this.get('auth.loggedHas.id')
          }
        });
      }

    }
  });

  _exports.default = _default;
});
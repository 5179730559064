define("da/components/palmares/nav-ui", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    voteService: Ember.inject.service('palmares'),
    paperToaster: Ember.inject.service('paperToaster'),
    classNames: ['nav-jury', 'animated'],
    lists: Ember.computed.alias('voteService.filteredLists'),
    keyBoardListenner: Ember.observer('voteService.keyBoardListenner', function () {
      var data = this.get('voteService.keyBoardListenner');
      this.setKeyBoardListenner(data);
    }).on('init'),
    activateKeyboard: Ember.on('init', function () {
      this.setKeyBoardListenner(true);
    }),

    setKeyBoardListenner(value) {
      this.set('keyboardActivated', value);
    },

    arrowLeft: Ember.on((0, _emberKeyboard.keyUp)('ArrowLeft'), function (event) {
      if (!this.get('disabledPrev')) {
        this.get('prev')(this.get('currentIndex') - 1);
      }
    }),
    arrowRight: Ember.on((0, _emberKeyboard.keyUp)('ArrowRight'), function (event) {
      if (!this.get('disabledNext')) {
        this.get('next')(this.get('currentIndex') + 1);
      }
    }),
    lists: Ember.computed.alias('voteService.filteredLists'),
    current: Ember.computed.alias('voteService.campagne'),
    currentIndex: Ember.computed('current.id', 'lists.length', function () {
      var current = this.get('current'),
          lists = this.get('lists'),
          index = lists.indexOf(current);
      return index;
    }),
    disabledPrev: Ember.computed('currentIndex', function () {
      return this.get('currentIndex') == 0 ? true : false;
    }),
    disabledNext: Ember.computed('currentIndex', function () {
      return this.get('currentIndex') == this.get('lists.length') - 1 ? true : false;
    }),

    resetUi() {
      this.$().toggleClass('slideOutDown').delay(500).queue(() => {
        this.$().toggleClass('slideOutDown');
      });
    },

    actions: {
      next: function () {
        if (!this.get('disabledNext')) {
          this.get('next')(this.get('currentIndex') + 1);
        }
      },
      prev: function () {
        if (!this.get('disabledPrev')) {
          this.get('prev')(this.get('currentIndex') - 1);
        }
      }
    }
  });

  _exports.default = _default;
});
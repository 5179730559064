define("da/routes/campagne/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),

    beforeModel() {
      var user = this.get('auth.user');

      if (!user.get('isAdmin')) {
        return this.transitionTo('/');
      }
    },

    model() {
      return this.store.findAll('user-api');
    }

  });

  _exports.default = _default;
});
define("da/components/creation/cover-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['creation-cover'],
    click: function () {
      var that = this;
      $('.creation-item .panel').animate({
        width: 0
      }, function () {
        that.get('back')();
      });
    }
  });

  _exports.default = _default;
});
define("da/controllers/juries/jury/creations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    voteService: Ember.inject.service('vote'),
    jury: Ember.inject.service('jury'),
    currentRoute: 1,
    application: Ember.inject.controller(),
    actions: {
      clearFilter() {
        this.get('voteService').resetFilter();
      },

      archive(jury) {
        let controller = this.get('application');
        controller.set('currentlyLoading', true);
        this.get('jury').getArchive(jury.id).then(function (data) {
          controller.set('currentlyLoading', false);
          window.open(data.url, '_blank');
        });
      }

    }
  });

  _exports.default = _default;
});
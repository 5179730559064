define("da/controllers/auth/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    title: Ember.computed('competition', function () {
      /*if (this.competition.libelle) {
        let title = this.competition.libelle.split('ᵉ').join('<sup>e</sup>');
        return title.split('°').join('<sup>e</sup>');
      }*/
      return 'Compétition & Concours';
    }),
    competition: Ember.computed(function () {
      return this.get('store').peekAll('competition').get('firstObject');
    }),
    isGeneral: Ember.computed('model.type', function () {
      return this.get('model.type') !== 'concours' && this.get('model.type') !== 'student' && this.get('model.type') !== 'competition';
    }),
    // concours étudiant
    isStudent: Ember.computed('model.type', function () {
      return this.get('model.type') === 'student';
    }),
    // concours futurs désirables
    isConcours: Ember.computed('model.type', function () {
      return this.get('model.type') === 'concours';
    }),
    isCompetition: Ember.computed('model.type', function () {
      return this.get('model.type') === 'competition';
    }),
    showLogin: Ember.computed('competition', function () {
      return this.get('competition').register_status || this.get('competition').register_student_status || this.get('competition').register_concours_status;
    }),
    actions: {
      registerCompetition(compet) {
        // concours étudiant
        if (compet === 'student') {
          return this.set('model.type', 'student');
        } // concours futurs désirables


        if (compet === 'concours') {
          return this.set('model.type', 'concours');
        } // compétition


        return this.set('model.type', 'competition');
      },

      back() {
        this.set('model.type', '');
      },

      login(model) {
        this.get('auth').login(model.get('email'), model.get('password')).then(response => {
          this.get('auth').setToken(response.access_token, response.expire);
          this.get('auth').currentUser().then(user => {
            if (user.data.attributes.role === 'judge') {
              this.transitionToRoute('juries');
            } else {
              this.transitionToRoute('campagne.index');
            }
          });
          this.transitionToRoute('campagne.index');
        }, error => {
          this.set('dialog.show', true);
          this.set('dialog.title', 'Erreur');
          this.set('dialog.message', 'L\'email ou le mot de passe n\'est pas correcte');
        });
      }

    }
  });

  _exports.default = _default;
});
define("da/models/campagne", ["exports", "ember-data", "moment", "da/mixins/inscription-ready"], function (_exports, _emberData, _moment, _inscriptionReady) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_inscriptionReady.default, {
    libelle: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    annonceur: _emberData.default.attr('string'),
    datePublication: _emberData.default.attr('string'),
    urlCampagne: _emberData.default.attr('string'),
    ready: _emberData.default.attr('boolean'),
    cover: _emberData.default.attr('string'),
    validateCeremony: _emberData.default.attr('boolean'),
    allowed: _emberData.default.attr(),
    sector: _emberData.default.belongsTo('sector'),
    creations: _emberData.default.hasMany('creation', {
      async: true
    }),
    categories: _emberData.default.hasMany('category'),
    ceremonies: _emberData.default.hasMany('ceremony'),
    categoryParents: _emberData.default.hasMany('category-parent'),
    credits: _emberData.default.hasMany('credit'),
    competition: _emberData.default.belongsTo('competition'),
    sortProperties: ['order:asc', 'qualityName:asc'],
    sortedCredits: Ember.computed.sort('credits', 'sortProperties'),
    libelleCategoryParents: Ember.computed('categoryParents.@each.libelle', function () {
      return this.get('categoryParents').mapBy('libelle').join();
    }),
    style: Ember.computed('cover', function () {
      return Ember.String.htmlSafe("background-image:url(\"" + this.get('cover') + "\")");
    }),
    formattedPublication: Ember.computed('datePublication', {
      get(key) {
        return (0, _moment.default)(key).format('DD/MM/YYYY');
      },

      set(key, value) {
        this.set('datePublication', (0, _moment.default)(value).format('DD/MM/YYYY'));
        return value;
      }

    }),
    canValidateCeremony: Ember.computed('ceremonies.@each.isValide', function () {
      return this.get('ceremonies').isAny('isValide', false) ? false : true;
    }),
    cantValidateCeremony: Ember.computed('canValidateCeremony', function () {
      return !this.get('canValidateCeremony');
    }),
    canValidateCredit: Ember.computed('selectedCredits.@each.isValidWithEmail', function () {
      return this.get('selectedCredits').isAny('isValidWithEmail', false) ? false : true;
    }),
    cantValidateCredit: Ember.computed('selectedCredits.@each.isValidWithEmail', function () {
      return this.get('selectedCredits').isAny('isValidWithEmail', false) ? true : false; //return !this.get('canValidateCredits');
    }),
    availableCreations: Ember.computed('creations.{length,@each.isNew}', function () {
      var data = this.get('creations').filter(function (creation) {
        return !creation.get('isNew');
      });
      return data;
    }),
    shortlistCreations: Ember.computed('availableCreations.length', function () {
      return this.get('availableCreations').filter(function (creation) {
        return creation.get('userCreations').filterBy('shortlist', true).get('length') > 0;
      });
    }),
    creationType: Ember.computed('creations.length', function () {
      return this.get('availableCreations').mapBy('creationType');
    }),
    hasCoverClassname: Ember.computed('cover', function () {
      if (this.get('cover')) {
        return 'hasCover';
      }

      return '';
    }),
    dynamicTitle: Ember.computed('availableCreations.length', function () {
      let n = this.get('availableCreations.length');

      if (n > 1) {
        return "Cette inscription comporte " + n + " créations :<br />";
      } else if (n === 1) {
        return "Cette inscription comporte " + n + " création :<br />";
      }

      return "Ajouter ici les créations (images, films ou sons) pour ce projet :";
    }),
    availableCredits: Ember.computed('sortedCredits.{length,@each.updated}', function () {
      return this.get('sortedCredits').filter(function (credit) {
        return !credit.get('isNew') && !credit.get('isValid');
      });
    }),
    selectedCredits: Ember.computed('sortedCredits.{length,@each.updated}', function () {
      return this.get('sortedCredits').filter(function (credit) {
        return credit.get('isValid');
      });
    }),
    nbCreations: Ember.computed('availableCreations.length', function () {
      var ids = this.hasMany('creations').ids();
      return ids.without(null).length;
    }),
    nbCategories: Ember.computed('categories.length', function () {
      return this.hasMany('categories').ids().length;
    }),
    nbCredits: Ember.computed('credits.length', function () {
      var ids = this.hasMany('credits').ids();
      return ids.without(null).length;
    }),
    hasSynopsis: Ember.computed('description', function () {
      return Ember.isEmpty(this.get('description')) ? 0 : 1;
    }),
    sectors: Ember.computed(function () {
      return this.get('store').peekAll('sector');
    })
  });

  _exports.default = _default;
});
define("da/controllers/campagne/edit/creation/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    campagne: Ember.computed.alias('model.campagne'),
    images: Ember.computed.alias('model.creation.images'),
    image: Ember.computed.filterBy('images', 'imageType', 'image'),
    prev: Ember.computed.filterBy('images', 'imageType', 'prev'),
    videos: Ember.computed.alias('model.creation.videos'),
    normalVideo: Ember.computed.filterBy('videos', 'videoType', 'normal'),
    smallVideo: Ember.computed.filterBy('videos', 'videoType', 'small'),
    actions: {
      update(creation) {
        if (creation.get('hasDirtyAttributes')) {
          creation.save();
        }
      },

      deleteImage: function (image) {
        image.destroyRecord();
      },
      back: function () {
        this.transitionToRoute('campagne.edit.creation.index', this.get('campagne.id'));
      },

      confirmation(creation) {
        this.get('dialog').setProperties({
          show: true,
          title: 'Supression de la creation',
          message: '<p>Voulez-vous vraiment supprimer cette creation ?</p>',
          actionOnAccept: this.get('actions.delete'),
          question: true,
          params: {
            creation
          }
        });
      },

      delete: function ({
        creation
      }) {
        var that = this;
        var campagne = creation.get('campagne');
        creation.destroyRecord().then(res => {
          campagne.get('creations').removeObject(res);
          this.get('dialog').reset();
          $('.creation-item .panel').animate({
            width: 0
          }, function () {
            that.transitionToRoute('campagne.edit.creation', campagne.get('id'));
          });
        });
      }
    }
  });

  _exports.default = _default;
});
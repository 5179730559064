define("da/routes/juries/auth/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      return Ember.RSVP.hash({
        user: this.get('store').createRecord('user-api'),
        competition: this.modelFor('application')
      });
    }
  });

  _exports.default = _default;
});
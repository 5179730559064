define("da/routes/juries/jury/creations/creation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    voteService: Ember.inject.service('vote'),
    auth: Ember.inject.service('auth'),

    activate() {
      Ember.$('body').toggleClass('creation-open');
    },

    deactivate() {
      Ember.$('body').toggleClass('creation-open');
    },

    beforeModel() {
      var params = this.paramsFor('juries.jury.creations.creation');
      var creationCategory = this.store.peekRecord('creation-category', params.list_id);
      var jury_id = this.paramsFor('juries.jury').jury_id;
      var jury = this.store.peekRecord('jury', jury_id);
      return this.get('voteService').setup(creationCategory, jury);
    },

    model: function (params) {
      var creation = this.get('voteService.creation');
      return Ember.RSVP.hash({
        user: this.get('voteService.user'),
        creation: this.get('store').findRecord('creation', creation.get('id'), {
          reload: true
        })
      });
    }
  });

  _exports.default = _default;
});
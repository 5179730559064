define("da/services/command", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),

    getCommand(id) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/commands/' + id,
        type: "GET",
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    getCommandPdf(id) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/commands/pdf/' + id,
        type: "GET",
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    }

  });

  _exports.default = _default;
});
define("da/controllers/juries/jury/creations/creation/panel/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    voteService: Ember.inject.service('vote'),
    paperToaster: Ember.inject.service('paperToaster'),
    comments: Ember.computed('model.comments.length', function () {
      return this.get('model.comments').filter(function (vote) {
        return Ember.isEmpty(vote.get('comment')) ? false : true;
      });
    }),
    actions: {
      update(vote) {
        vote.save().then(vote => {
          this.get('paperToaster').show('Votre commentaire a bien été enregistré', {
            duration: 3000,
            position: 'top left',
            accent: true
          });
        });
      }

    }
  });

  _exports.default = _default;
});
define("da/components/creation/content-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['creation-item'],
    hide: false,
    actions: {
      update(creation) {
        this.get('update')(creation);
      },

      back() {
        this.get('back');
      },

      delete(creation) {
        this.get('delete')(creation);
      }

    }
  });

  _exports.default = _default;
});
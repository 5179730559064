define("da/templates/juries/jury/creations/creation/panel/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3zFeNZZv",
    "block": "{\"symbols\":[\"vote\",\"card\",\"form\"],\"statements\":[[4,\"unless\",[[24,[\"voteService\",\"canAccessVotes\"]]],null,{\"statements\":[[4,\"paper-form\",null,[[\"focusOut\"],[[28,\"action\",[[23,0,[]],\"update\",[24,[\"model\",\"vote\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"paper-input\",null,[[\"textarea\",\"block\",\"label\",\"maxlength\",\"passThru\",\"value\",\"onChange\"],[true,true,\"Ajouter un commentaire\",180,[28,\"hash\",null,[[\"rows\",\"maxRows\"],[5,5]]],[24,[\"model\",\"vote\",\"comment\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"vote\",\"comment\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"voteService\",\"canAccessVotes\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"comments\"]]],null,{\"statements\":[[4,\"paper-card\",null,null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"hour\"],[8],[0,\"\\n        \"],[1,[23,1,[\"time\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"name\"],[8],[0,\"\\n        \"],[1,[23,1,[\"fullName\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n        \"],[1,[23,1,[\"comment\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/juries/jury/creations/creation/panel/comment.hbs"
    }
  });

  _exports.default = _default;
});
define("da/models/creation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    libelle: _emberData.default.attr('string'),
    creationType: _emberData.default.attr('string', {
      defaultValue: 'image'
    }),
    campagne: _emberData.default.belongsTo('campagne'),
    userCreations: _emberData.default.hasMany('user-creation'),
    script: _emberData.default.attr('string'),
    images: _emberData.default.hasMany('image'),
    videos: _emberData.default.hasMany('video'),
    audios: _emberData.default.hasMany('audio'),
    previews: Ember.computed.filterBy('images', 'imageType', 'prev'),
    cover: Ember.computed('previews.length', 'previews.@each.pathFile', function () {
      return this.get('previews.firstObject.pathFile');
    }),
    style: Ember.computed('cover', function () {
      return Ember.String.htmlSafe("background-image:url(\"" + this.get('cover') + "\")");
    }),
    nbVideo: Ember.computed('videos.length', function () {
      return this.hasMany('videos').ids().length;
    }),
    hasShortlist: Ember.computed('userCreations.length', function () {
      return this.get('userCreations').filterBy('shortlist', true).get('length') > 0;
    }),
    medias: Ember.A([{
      key: 'image',
      label: 'Image'
    }, {
      key: 'video',
      label: 'Vidéo'
    }, {
      key: 'audio',
      label: 'Audio'
    }]),
    selectedType: Ember.computed('creationType', {
      get(key) {
        var current = this.get('creationType');
        var check = this.get('medias').findBy('key', current);
        return check ? check : this.get('medias').get('firstObject');
      },

      set(key, value) {
        this.set('creationType', value.key);
        return value;
      }

    }),
    url: Ember.computed('creationType', function () {
      return 'campagne.edit.creation.' + this.get('creationType');
    })
  });

  _exports.default = _default;
});
define("da/routes/juries/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    model: function () {
      return this.get('auth').currentUser().then(user => {
        this.store.pushPayload(user);
        var user = this.store.peekRecord('user-api', user.data.id);

        if (user.get('canAccessJury')) {
          return this.transitionTo('juries.toc'); //return this.transitionTo('juries.jury', 0);
        }

        return this.transitionTo('campagne.index');
      }, error => {
        return this.transitionTo('juries.auth.login');
      });
    }
  });

  _exports.default = _default;
});
define("da/templates/components/palmares/video-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T5sEOYgK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[22,\"vimeo\"],true],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/palmares/video-item.hbs"
    }
  });

  _exports.default = _default;
});
define("da/components/date-ui", ["exports", "paper-pikaday/components/paper-pikaday", "moment"], function (_exports, _paperPikaday, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultFormat = 'DD/MM/YYYY';

  var _default = _paperPikaday.default.extend({
    userSelectedDate() {
      var selectedDate = this.get('pikaday').getDate();

      if (this.get('useUTC')) {
        selectedDate = _moment.default.utc([selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()]).toDate();
      }

      this.get('onChange')((0, _moment.default)(selectedDate).format(defaultFormat)); //this.sendAction('onChange', moment(selectedDate).format(defaultFormat));
    },

    setPikadayDate: function () {
      //const format = 'DD/MM/YYYY';
      const value = this.get('value');

      if (!value) {
        this.get('pikaday').setDate(value, true);
      } else {
        const date = (0, _moment.default)(value, defaultFormat).toDate();
        this.get('pikaday').setDate(date, true);
      }
    }
  });

  _exports.default = _default;
});
define("da/serializers/vimeo-video", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    normalize(typeClass, hash) {
      hash.id = hash.uri.split('/')[2];
      return this._super(typeClass, hash);
    }

  });

  _exports.default = _default;
});
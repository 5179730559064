define("da/components/jury/filter-user-creation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    voteService: Ember.inject.service('vote'),
    classNames: ['user-creation'],
    classNameBindings: ['isShortlist', 'isNominate', 'isMention', 'isPrice'],
    isShortlist: Ember.computed.alias('voteService.showShortlist'),
    isNominate: Ember.computed.alias('voteService.showNomination'),
    isMention: Ember.computed.alias('voteService.showMention'),
    isPrice: Ember.computed.alias('voteService.showPrice'),
    actions: {
      shortlist() {
        var shortlist = this.get('voteService.showShortlist');

        if (shortlist) {
          this.set('voteService.showShortlist', false);
        } else {
          this.set('voteService.showShortlist', true);
        }

        if (this.get('voteService.recursive')) {
          this.set('voteService.showNomination', false);
          this.set('voteService.showMention', false);
          this.set('voteService.showPrice', false);
        }
      },

      nomination() {
        var nomination = this.get('voteService.showNomination');

        if (nomination) {
          this.set('voteService.showNomination', false);
        } else {
          this.set('voteService.showNomination', true);
        }

        if (this.get('voteService.recursive')) {
          this.set('voteService.showShortlist', false);
          this.set('voteService.showMention', false);
          this.set('voteService.showPrice', false);
        }
      },

      mention() {
        var mention = this.get('voteService.showMention');

        if (mention) {
          this.set('voteService.showMention', false);
        } else {
          this.set('voteService.showMention', true);
        }

        if (this.get('voteService.recursive')) {
          this.set('voteService.showShortlist', false);
          this.set('voteService.showNomination', false);
          this.set('voteService.showPrice', false);
        }
      },

      price() {
        var price = this.get('voteService.showPrice');

        if (price) {
          this.set('voteService.showPrice', false);
        } else {
          this.set('voteService.showPrice', true);
        }

        if (this.get('voteService.recursive')) {
          this.set('voteService.showShortlist', false);
          this.set('voteService.showNomination', false);
          this.set('voteService.showMention', false);
        }
      }

    }
  });

  _exports.default = _default;
});
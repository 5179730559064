define("da/templates/juries/jury/creations/creation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "39NatP5h",
    "block": "{\"symbols\":[],\"statements\":[[4,\"jury/creation/container-ui\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content-jury\"],[8],[0,\"\\n    \"],[1,[28,\"component\",[\"jury/nav-ui\"],[[\"next\",\"prev\"],[[28,\"action\",[[23,0,[]],\"next\"],null],[28,\"action\",[[23,0,[]],\"prev\"],null]]]],false],[0,\"\\n    \"],[1,[28,\"component\",[[24,[\"path\"]]],[[\"creation\"],[[24,[\"model\",\"creation\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/juries/jury/creations/creation.hbs"
    }
  });

  _exports.default = _default;
});
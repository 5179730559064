define("da/components/jury/creation/container-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['jury', 'creation-item', 'animated'],
    classNameBindings: ['zoomInDown:zoomInDown'],
    zoomInDown: false
  });

  _exports.default = _default;
});
define("da/controllers/campagne/edit/creation/audio", ["exports", "da/controllers/campagne/edit/creation/video"], function (_exports, _video) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _video.default.extend({
    audios: Ember.computed.alias('model.creation.audios'),
    hasAudio: Ember.computed('audios.length', function () {
      return this.get('audios.length') > 0 ? true : false;
    }),
    canUploadPrev: Ember.computed('audios.length', function () {
      if (this.get('audios.length') === 1) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});
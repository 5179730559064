define("da/initializers/setup-pikaday-i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'setup-pikaday-i18n',
    initialize: function (application) {
      moment.locale('fr');
      var i18n = Ember.Object.extend({
        previousMonth: 'Vorheriger Monat',
        nextMonth: 'Nächster Monat',
        months: moment.localeData()._months,
        weekdays: moment.localeData()._weekdays,
        weekdaysShort: moment.localeData()._weekdaysShort
      });
      application.register('pikaday-i18n:main', i18n, {
        singleton: true
      });
      application.inject('component:date-ui', 'i18n', 'pikaday-i18n:main');
    }
  };
  _exports.default = _default;
});
define("da/adapters/vimeo-video", ["exports", "da/adapters/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Adapter.extend({
    vimeo: Ember.inject.service(),
    findAll: function () {
      return this.get('vimeo').videos();
    },
    findRecord: function (model, options, id) {
      return this.get('vimeo').findRecord(id);
    }
  });

  _exports.default = _default;
});
define("da/components/categories/children-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dialog: Ember.inject.service(),
    tagName: 'li',
    creations: Ember.computed.alias('campagne.creations'),
    types: Ember.computed.intersect('campagne.creationType', 'category.categoryParent.forbiddenTypes'),
    hasWarning: Ember.computed('types.length', function () {
      return this.get('types.length') > 0 ? true : false;
    }),
    click: function () {
      if (this.get('hasWarning')) {
        this.get('dialog').setProperties({
          show: true,
          title: 'Attention',
          message: 'Vous inscrivez des créations de type ' + this.get('types').join() + ' dans la catégorie ' + this.get('category.categoryParent.libelle'),
          question: true,
          labelYes: 'je sais ce que je fais',
          labelNo: 'Annuler',
          actionOnAccept: this.get('add'),
          params: {
            categories: this.get('campagne.categories'),
            campagne: this.get('campagne'),
            category: this.get('category')
          }
        });
      } else {
        this.get('campagne.categories').pushObject(this.get('category'));
        this.get('campagne').save();
      }
    },
    add: function () {
      this.params.categories.pushObject(this.params.category);
      this.params.campagne.save();
      this.get('dialog').reset();
    },
    actions: {}
  });

  _exports.default = _default;
});
define("da/routes/juries/jury/creations/creation/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    voteService: Ember.inject.service('vote'),
    model: function () {
      var model = this.modelFor('juries.jury.creations.creation');
      var params = {
        category_id: this.get('voteService.category.id'),
        jury_id: this.get('voteService.jury.id'),
        creation_id: this.get('voteService.creation.id')
      };
      this.store.peekAll('user-api').setEach('votes', []);
      return this.store.query('vote', params).then(users => {
        return Ember.RSVP.hash({
          user: model.user,
          comments: users,
          voteService: this.get('voteService')
        });
      });
    },

    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      this.controllerFor('juries.jury.creations.creation.panel').set('currentRoute', this.get('routeName'));
    }

  });

  _exports.default = _default;
});
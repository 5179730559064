define("da/templates/components/categories/categories-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "deH8ipr7",
    "block": "{\"symbols\":[\"category\",\"categoryParent\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"categories\"]]],null,{\"statements\":[[0,\"  \"],[7,\"style\",true],[8],[0,\"\\n    .category-c\"],[1,[23,2,[\"id\"]],false],[0,\" {\\n      background:\"],[1,[23,2,[\"color\"]],false],[0,\";\\n      color:\"],[1,[23,2,[\"color_text\"]],false],[0,\";\\n\\n    }\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[7,\"div\",true],[10,\"class\",\"layout-column layout-gt-sm-row layout-padding\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-order-2 flex-order-gt-sm-1 flex-gt-sm-80\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[0,\"Choisir une ou plusieurs catégorie pour cette inscription\"],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"category-list\"],[8],[0,\"\\n        \"],[1,[28,\"component\",[\"categories/menu-ui\"],[[\"items\",\"campagne\"],[[24,[\"categories\"]],[24,[\"campagne\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-order-1 flex-order-gt-sm-1 flex-gt-sm-20\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"selection\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"Votre sélection\"],[9],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"campagne\",\"categories\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"component\",[\"categories/categories-selected\"],[[\"category\",\"campagne\"],[[23,1,[]],[24,[\"campagne\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/categories/categories-list.hbs"
    }
  });

  _exports.default = _default;
});
define("da/components/vimeo-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    vimeo: Ember.inject.service(),
    upload: Ember.inject.service(),
    store: Ember.inject.service(),
    upload_link_secure: null,
    complete_uri: null,
    file: null,
    uploadedByte: 0,
    videoType: 'normal',
    totalByte: Ember.computed.alias('file.size'),
    showValidate: Ember.computed('file', function () {
      return this.get('file') instanceof File;
    }),
    done: Ember.computed('uploadedByte', 'totalByte', function () {
      return this.get('totalByte') === this.get('uploadedByte');
    }),
    check: function () {
      this.get('vimeo').check(this.get('upload_link_secure')).then(response => {
        var range = response['Upload-Offset'];
        this.set('uploadedByte', parseInt(range));

        if (!this.get('done')) {
          Ember.run.later(this, function () {
            this.check();
          }, 10000);
        } else {
          this.publish(this.get('uploadedByte'));
        }
      });
    },
    publish: function (uploadOffset) {
      this.get('vimeo').complete(this.get('upload_link_secure'), uploadOffset).then(response => {
        let vimeoPath = this.get('vimeo_path');
        var creation_id = this.get('creation.id');
        var video_type = this.get('videoType');
        this.get('upload').videos(creation_id, vimeoPath, video_type).then(video => {
          this.get('store').pushPayload(video);
          this.set('file', null);
          this.$().parent().find('.file').val('');
        });
      });
    },
    actions: {
      validate: function () {
        this.get('vimeo').ticket(this.get('file')).then(response => {
          this.set('upload_link_secure', response.upload_link);
          this.set('vimeo_path', response.vimeo_path);
          this.check();
        });
      }
    }
  });

  _exports.default = _default;
});
define("da/templates/components/jury/filter-user-creation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Nt+x6xv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"shortlist item\"],[8],[0,\"\\n\"],[4,\"paper-button\",null,[[\"raised\",\"mini\",\"accent\",\"onClick\"],[true,true,true,[28,\"action\",[[23,0,[]],\"shortlist\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"paper-icon\",[\"star-border\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"nomination  item\"],[8],[0,\"\\n\"],[4,\"paper-button\",null,[[\"raised\",\"mini\",\"accent\",\"onClick\"],[true,true,true,[28,\"action\",[[23,0,[]],\"nomination\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"paper-icon\",[\"star-border\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\\n\"],[7,\"div\",true],[10,\"class\",\"mention  item\"],[8],[0,\"\\n\"],[4,\"paper-button\",null,[[\"raised\",\"mini\",\"accent\",\"onClick\"],[true,true,true,[28,\"action\",[[23,0,[]],\"mention\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"paper-icon\",[\"star-border\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"price  item\"],[8],[0,\"\\n\"],[4,\"paper-button\",null,[[\"raised\",\"mini\",\"accent\",\"onClick\"],[true,true,true,[28,\"action\",[[23,0,[]],\"price\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"paper-icon\",[\"star-border\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/jury/filter-user-creation.hbs"
    }
  });

  _exports.default = _default;
});
define("da/mixins/inscription-ready", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    error: Ember.A(),

    canReady() {
      let creations = this.get('availableCreations');
      this.checkCreations(creations);
      this.checkCategories();
      this.checkSynopsis(); //this.checkCredit();

      var texte = '<p>';
      this.get('error').forEach(error => {
        texte = texte + error.message + '<br/>';
      });
      texte = texte + '</p>';
      this.set('error.errorHasText', texte);
      return this.get('error');
    },

    checkCreations(creations) {
      if (creations.get('length') === 0) {
        this.buildErrorMessage('creation', {
          message: 'il n\'y a pas de création pour cette inscription',
          path: {
            url: 'campagne.edit.creation.index',
            params: [this.id]
          }
        });
      }

      var videos = creations.filterBy('creationType', 'video');
      var images = creations.filterBy('creationType', 'image');
      var audios = creations.filterBy('creationType', 'audio');
      this.checkVideo(videos);
      this.checkImage(images);
      this.checkAudio(audios);
    },

    checkSynopsis() {
      if (this.get('hasSynopsis')) {
        return false;
      }

      this.buildErrorMessage('synospis', {
        message: 'il n\'y a pas de synopsis pour cette inscription',
        path: {
          url: 'campagne.edit.synopsis',
          params: [this.id]
        }
      });
    },

    checkCategories() {
      if (this.get('categories.length') > 0) {
        return false;
      }

      this.buildErrorMessage('categories', {
        message: 'il n\'y a pas de catégorie associé pour cette inscription',
        path: {
          url: 'campagne.edit.categories',
          params: [this.id]
        }
      });
    },

    checkVideo(creations) {
      if (creations.get('length') === 0) {
        return false;
      }

      creations = creations.filter(creation => {
        return creation.get('videos.length') === 0;
      });
      creations.forEach(creation => {
        this.buildErrorMessage('creation', {
          message: 'la création <b>' + creation.get('libelle') + '</b> n\'a pas de vidéo',
          path: {
            url: 'campagne.edit.creation.video',
            params: [this.id, creation.get('id')]
          }
        });
      });
    },

    checkImage(creations) {
      if (creations.get('length') === 0) {
        return false;
      }

      creations = creations.filter(creation => {
        return creation.get('images.length') === 0;
      });
      creations.forEach(creation => {
        this.buildErrorMessage('creation', {
          message: 'la création ' + creation.get('libelle') + ' n\'a pas d\'image',
          path: {
            url: 'campagne.edit.creation.image',
            params: [this.id, creation.get('id')]
          }
        });
      });
    },

    checkAudio(creations) {
      if (creations.get('length') === 0) {
        return false;
      }

      creations = creations.filter(creation => {
        return creation.get('audios.length') === 0;
      });
      creations.forEach(creation => {
        this.buildErrorMessage('creation', {
          message: 'la création ' + creation.get('libelle') + ' n\'a pas de fichier audio',
          path: {
            url: 'campagne.edit.creation.audio',
            params: [this.id, creation.get('id')]
          }
        });
      });
    },

    checkCredit() {
      var selected = this.get('selectedCredits').map(selectedCategory => selectedCategory.get('quality.id'));
      var compares = selected.filter(selected => this.get('allowed').includes(selected));
      compares = compares.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      console.log('allowed:', this.get('allowed'), ' selected:', compares);

      if (selected.length > 0 && compares.length === this.get('allowed.length')) {
        return false;
      }

      this.buildErrorMessage('credit', {
        message: 'Vous devez compléter tous les crédits requis pour cette campagne.',
        path: {
          url: 'campagne.edit.credit.index',
          params: [this.id]
        }
      });
    },

    buildErrorMessage(type, hash) {
      let errors = this.get('error');
      errors.pushObject(hash);
      return errors;
    }

  });

  _exports.default = _default;
});
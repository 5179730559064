define("da/routes/campagne/edit/synopsis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    competition: Ember.inject.service(),
    model: function () {
      let campagne = this.modelFor('campagne.edit');
      return this.get('competition').getCampagnes(campagne, 'synopsis').then(campagnes => {
        campagnes = Ember.ArrayProxy.create({
          content: Ember.A(campagnes.data)
        });
        return Ember.RSVP.hash({
          campagne: campagne,
          campagnes: campagnes,
          selectedCampagne: campagnes.get('firstObject')
        });
      });
    },

    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      this.controllerFor('campagne.edit').set('currentRoute', this.get('routeName'));
    }

  });

  _exports.default = _default;
});
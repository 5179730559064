define("da/helpers/vimeo-thumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.thumbVimeo = thumbVimeo;
  _exports.default = void 0;

  function thumbVimeo([model, width, height]) {
    var thumb = model.get('sizes').find(function (thumb, index) {
      return thumb.width === width && thumb.height === height;
    });
    return thumb.link;
  }

  var _default = Ember.Helper.helper(thumbVimeo);

  _exports.default = _default;
});
define("da/models/vote", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    creation: _emberData.default.belongsTo('creation'),
    category: _emberData.default.belongsTo('category'),
    users: _emberData.default.belongsTo('user-api'),
    jury: _emberData.default.belongsTo('jury'),
    choice: _emberData.default.attr('number', {
      defaultValue: 2
    }),
    comment: _emberData.default.attr('string'),
    fullName: _emberData.default.attr('string'),
    time: _emberData.default.attr('string'),
    like: Ember.computed('choice', function () {
      return this.get('choice') === 0 ? 'no' : 'yes';
    })
  });

  _exports.default = _default;
});
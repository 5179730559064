define("da/templates/components/vimeo-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cQNAluk2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"video\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"publish\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"player\"],true],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"unless\",[[24,[\"publish\"]]],null,{\"statements\":[[0,\"    Votre vidéo est en cours d’encodage.\"],[7,\"br\",true],[8],[9],[0,\"\\n    Vous pouvez patienter quelques instants ou continuer vos inscriptions et revenir ultérieurement.\\n    \"],[1,[22,\"paper-progress-circular\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"couldUploadSmallVideo\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[0,\"Nous vous invitons à envoyer un version courte de cette vidéo, 30 secondes maximum\\n    \"],[7,\"small\",true],[8],[0,\"(facultatif)\"],[9],[0,\"\\n     :\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"component\",[\"vimeo-upload\"],[[\"name\",\"creation\",\"videoType\"],[\"Envoyer une version courte\",[24,[\"video\",\"creation\"]],\"small\"]]],false],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"help\"],[8],[0,\"Si vous n’envoyez pas de version courte, les 30 premières secondes de la vidéos seront utilisées\\n    pour la cérémonie de remise des prix.\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/vimeo-player.hbs"
    }
  });

  _exports.default = _default;
});
define("da/templates/components/categories/children-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bqz9QMuC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"sub-category-label\"],[8],[0,\"\\n    \"],[1,[24,[\"category\",\"libelle\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"category\",\"detail\"]]],null,{\"statements\":[[0,\"    \"],[7,\"em\",true],[8],[1,[24,[\"category\",\"detail\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/categories/children-ui.hbs"
    }
  });

  _exports.default = _default;
});
define("da/routes/juries/jury/creations/creation/panel/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    voteService: Ember.inject.service('vote'),
    model: function () {
      var model = this.modelFor('juries.jury.creations.creation.panel');
      return Ember.RSVP.hash({
        vote: this.get('voteService.vote'),
        comments: model.comments,
        user: model.user
      });
    },
    activate: function () {
      var model = this.modelFor('juries.jury.creations.creation.panel');
      this.controllerFor('juries.jury.creations.creation').set('currentRoute', this.get('routeName'));

      if (!model.user.get('isAdmin')) {
        this.set('voteService.keyBoardListenner', false);
      }
    },
    deactivate: function () {
      this.set('voteService.keyBoardListenner', true);
    },

    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      this.controllerFor('juries.jury.creations.creation.panel').set('currentRoute', this.get('routeName'));
    }

  });

  _exports.default = _default;
});
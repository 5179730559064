define("da/adapters/application", ["exports", "ember-data", "da/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    auth: Ember.inject.service(),
    host: _environment.default.host,
    namespaceService: Ember.inject.service('namespace'),
    namespace: Ember.computed.alias('namespaceService.namespace'),
    headers: Ember.Object.create(),
    setAuthorization: Ember.on('init', Ember.observer('auth.token', function () {
      this.set('headers.Authorization', this.get('auth').authorization());
      return this.get('auth.token');
    })),
    setLoggedHas: Ember.on('init', Ember.observer('auth.loggedHas', function () {
      this.set('headers.LoggedHas', this.get('auth.loggedHas.id'));
    }))
  });

  _exports.default = _default;
});
define("da/templates/components/upload-ui/upload-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XHVGiDwq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"paper-progress-linear\",null,[[\"value\",\"accent\"],[[24,[\"pourcent\"]],true]]],false],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/upload-ui/upload-progress.hbs"
    }
  });

  _exports.default = _default;
});
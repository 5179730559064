define("da/templates/components/upload-ui/button-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "73PtZdRY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"paper-button\",null,[[\"class\",\"raised\",\"primary\"],[\"uploadIt bt-icon\",true,true]],{\"statements\":[[0,\"  \"],[1,[28,\"paper-icon\",[\"file-upload\"],null],false],[0,\"\\n  \"],[1,[22,\"name\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/upload-ui/button-upload.hbs"
    }
  });

  _exports.default = _default;
});
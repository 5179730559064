define("da/components/jury/creation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    voteService: Ember.inject.service('vote'),
    canAccessVotes: Ember.computed.alias('voteService.canAccessVotes'),
    canAccessJury: Ember.computed.alias('voteService.canAccessJury'),
    store: Ember.inject.service('store'),
    classNameBindings: ['hasCover', 'fadeIn:fadeIn'],
    hasCover: Ember.computed.alias('list.hasCoverClassname'),
    nbVotes: Ember.computed.alias('list.nbVotes'),
    nbVotesNo: Ember.computed.alias('list.nbVotesNo'),
    votesYes: Ember.computed.alias('list.votesYes'),
    votesNo: Ember.computed.alias('list.votesNo'),
    fadeIn: true,
    imageLoading: false,
    classNames: ['flex-100', 'flex-gt-xs-50', 'flex-gt-sm-33', 'gutter', 'creation-category', 'animated'],
    style: Ember.String.htmlSafe(''),
    loadImg: Ember.observer('list.imagePreview', function () {
      if (Ember.isEmpty(this.get('list.imagePreview'))) {
        return false;
      }

      var image = new Image();
      image.src = this.get('list.imagePreview');
      this.set('imageLoading', true);

      image.onload = () => {
        if (this.get('isDestroyed')) {
          return false;
        }

        this.set('imageLoading', false);
        this.set('style', Ember.String.htmlSafe("background-image:url(\"" + this.get('list.imagePreview') + "\")"));
      };
    }).on('didInsertElement'),
    canDownload: Ember.computed('hasVideo', 'canAccessJury', function () {
      return this.get('hasVideo') && this.get('canAccessJury');
    }),
    hasVideo: Ember.computed('list.videoPath', function () {
      return Ember.isEmpty(this.get('list.videoPath')) ? false : true;
    }),
    hasImage: Ember.computed('list.downloadImage', function () {
      return Ember.isEmpty(this.get('list.downloadImage')) ? false : true;
    }),
    hasAudio: Ember.computed('list.downloadAudio', function () {
      return Ember.isEmpty(this.get('list.downloadAudio')) ? false : true;
    }),
    canDownloadImage: Ember.computed('canAccessJury', 'hasImage', function () {
      return !this.get('canDownload') && this.get('hasImage') && this.get('canAccessJury');
      ;
    }),
    canDownloadAudio: Ember.computed('canAccessJury', 'hasAudio', function () {
      return this.get('hasAudio') && this.get('canAccessJury');
    }),
    hasVote: Ember.computed('nbVotes', 'canFavorite', 'voteService.showNbVote', function () {
      return this.get('canFavorite') && this.get('voteService.showNbVote');
    }),
    totalVotes: Ember.computed('nbVotes', 'nbVotesNo', function () {
      return this.get('nbVotes') + this.get('nbVotesNo');
    }),
    canFavorite: Ember.computed.alias('canAccessVotes'),
    actions: {
      download() {
        var id = this.get('list.videoPath').split('/')[2];
        this.get('store').findRecord('vimeo-video', id).then(video => {
          window.open(video.get('downloadLink'), "_blank");
        });
      }

    },

    willDestroyElement() {
      var clone = this.$().clone();
      this.$().parent().append(clone);
      clone.toggleClass('fadeOut').delay(500).queue(() => {
        clone.remove();
      });
    }

  });

  _exports.default = _default;
});
define("da/serializers/campagne", ["exports", "da/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    store: Ember.inject.service(),

    serialize(snapshot, options) {
      var json = this._super(...arguments);

      if (!Ember.isEmpty(json.data.relationships) && !Ember.isEmpty(json.data.relationships.sector)) {
        json.data.attributes['ident'] = json.data.relationships.sector.data.id;
      }

      if (!Ember.isEmpty(json.data.relationships) && !Ember.isEmpty(json.data.relationships.categories)) {
        json.data['categories'] = json.data.relationships.categories.data;
      }

      if (!Ember.isEmpty(json.data.relationships) && !Ember.isEmpty(json.data.relationships.competition)) {
        json.data['idcompetition'] = json.data.relationships.competition.data.id;
      }

      return json;
    },

    keyForRelationship(key, relationship, method) {
      return Ember.String.underscore(key);
    }

  });

  _exports.default = _default;
});
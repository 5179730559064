define("da/routes/campagne/edit/creation/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate: function () {
      Ember.$('body').toggleClass("panelOpened");
      Ember.$('.edit').find('.liquid-child').attr('style', '');
      Ember.$(window).scrollTop(0);
    },
    deactivate: function () {
      Ember.$('body').toggleClass("panelOpened");
    },
    model: function (params) {
      let creation = this.get('store').findRecord('creation', params.creation_id);
      let campagne = this.modelFor('campagne.edit');
      return Ember.RSVP.hash({
        creation: creation,
        campagne: campagne
      });
    }
  });

  _exports.default = _default;
});
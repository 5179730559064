define("da/templates/components/jury/creation/video-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xru/jXRk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"iframe\",true],[11,\"src\",[29,[[24,[\"vimeo\",\"content\",\"player_embed_url\"]]]]],[10,\"width\",\"1280\"],[10,\"height\",\"720\"],[10,\"frameborder\",\"0\"],[10,\"webkitallowfullscreen\",\"\"],[10,\"mozallowfullscreen\",\"\"],[10,\"allowfullscreen\",\"\"],[8],[0,\" \"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/jury/creation/video-item.hbs"
    }
  });

  _exports.default = _default;
});
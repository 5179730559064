define("da/components/palmares/image-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['creation-image', 'animated'],
    image: Ember.computed('creation.images.length', function () {
      return this.get('creation.images').filterBy('image_type', 'image').get('firstObject.thumb');
    })
  });

  _exports.default = _default;
});
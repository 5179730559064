define("da/components/palmares/creation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flex-100', 'flex-gt-xs-50', 'flex-gt-sm-33', 'gutter', 'creation-category', 'animated'],
    classNameBindings: ['hasCover:hasCover'],
    award: Ember.computed('list.score', function () {
      let score = parseInt(this.get('list.score'));

      if (score === 1) {
        return 'Shortlist';
      }

      if (score === 2) {
        return '3ème Prix';
      }

      if (score === 3) {
        return '2ème Prix';
      }

      if (score === 4) {
        return '1er Prix';
      }
    }),
    hasCover: Ember.computed('list.cover', function () {
      if (this.get('list.cover')) return true;
      return false;
    }),
    loadImg: Ember.observer('list.cover', function () {
      if (Ember.isEmpty(this.get('list.cover'))) {
        return false;
      }

      var image = new Image();
      image.src = this.get('list.cover');
      this.set('imageLoading', true);

      image.onload = () => {
        if (this.get('isDestroyed')) {
          return false;
        }

        this.set('imageLoading', false);
        this.set('style', Ember.String.htmlSafe("background-image:url(\"" + this.get('list.cover') + "\")"));
      };
    }).on('didInsertElement'),
    canDownload: Ember.computed('hasVideo', function () {
      return this.get('hasVideo');
    }),
    hasVideo: Ember.computed('list.videoPath', function () {
      return Ember.isEmpty(this.get('list.videoPath')) ? false : true;
    }),
    hasImage: Ember.computed('list.downloadImage', function () {
      return Ember.isEmpty(this.get('list.downloadImage')) ? false : true;
    }),
    hasAudio: Ember.computed('list.downloadAudio', function () {
      return Ember.isEmpty(this.get('list.downloadAudio')) ? false : true;
    }),
    canDownloadImage: Ember.computed('hasImage', function () {
      return !this.get('canDownload') && this.get('hasImage');
    }),
    canDownloadAudio: Ember.computed('hasAudio', function () {
      return this.get('hasAudio');
    }),
    actions: {
      download() {
        var id = this.get('list.videoPath').split('/')[2];
        this.get('store').findRecord('vimeo-video', id).then(video => {
          window.location = video.get('downloadLink');
        });
      }

    },

    willDestroyElement() {
      var clone = this.$().clone();
      this.$().parent().append(clone);
      clone.toggleClass('fadeOut').delay(500).queue(() => {
        clone.remove();
      });
    }

  });

  _exports.default = _default;
});
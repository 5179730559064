define("da/templates/juries/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3B0pHTOv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-row layout-margin layout-align-center isHome\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-100 flex-gt-md-80\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title page-title\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"/\"],[10,\"rel\",\"noopener\"],[10,\"class\",\"logo\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"/i/club-des-da-logo@300x.png\"],[10,\"alt\",\"Le club des Directeurs Artistiques\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"h1\",true],[8],[0,\"Jury\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/juries/auth.hbs"
    }
  });

  _exports.default = _default;
});
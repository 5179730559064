define("da/components/palmares/video-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['creation-video', 'animated'],
    video: Ember.computed('creation.videos.length', function () {
      return this.get('creation.videos').filterBy('video_type', 'normal').get('firstObject');
    }),
    vimeo: Ember.computed('video.id', function () {
      if (Ember.isEmpty(this.get('video'))) {
        this.get('paperToaster').show('Il n\'y a pas de vidéo pour cette campagne', {
          duration: 4000,
          position: 'top left',
          accent: true
        });
        return false;
      }

      var id = this.get('video.vimeo_path').split('/')[2];
      return '<div class="video-embed"><iframe src="https://player.vimeo.com/video/' + id + '?autoplay=0&badge=0&autopause=0&player_id=0&api=1 " width="1280" height="720" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen> </iframe></div>';
    })
  });

  _exports.default = _default;
});
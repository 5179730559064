define("da/routes/palmares/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    palmares: Ember.inject.service(),

    activate() {
      Ember.$('body').toggleClass('creation-open');
    },

    deactivate() {
      Ember.$('body').toggleClass('creation-open');
    },

    beforeModel() {
      var id = this.paramsFor('palmares.show').list_id;
      var current = this.get('palmares.data').findBy('id', id);
      this.set('palmares.campagne', current);
    },

    model(params) {
      var id = params.list_id.split('k')[0];
      var category_id = params.list_id.split('k')[1];
      return this.get('palmares').show(id, category_id).then(campagne => {
        if (campagne.creations) {
          campagne.creations = Ember.ArrayProxy.create({
            content: Ember.A(campagne.creations)
          });

          if (campagne.creations.get('videos')) {
            campagne.creations.set('videos', Ember.ArrayProxy.create({
              content: Ember.A(campagne.creations.videos)
            }));
          }

          if (campagne.creations.get('images')) {
            campagne.creations.set('images', Ember.ArrayProxy.create({
              content: Ember.A(campagne.creations.images)
            }));
          }

          if (campagne.creations.get('audios')) {
            campagne.creations.set('audios', Ember.ArrayProxy.create({
              content: Ember.A(campagne.creations.audios)
            }));
          }
        }

        return Ember.RSVP.hash({
          campagne: campagne,
          item: this.get('palmares.campagne')
        });
      });
    }

  });

  _exports.default = _default;
});
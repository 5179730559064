define("da/routes/juries/jury/creations/creation/panel/votes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    voteService: Ember.inject.service('vote'),
    model: function () {
      var model = this.modelFor('juries.jury.creations.creation.panel');

      if (!this.get('voteService.canAccessVotes')) {
        return this.transitionTo('juries.jury.creations.creation');
      }

      return Ember.RSVP.hash({
        vote: this.get('voteService.vote'),
        votes: model.comments,
        user: model.user
      });
    },
    activate: function () {
      this.controllerFor('juries.jury.creations.creation').set('currentRoute', this.get('routeName'));
    },

    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      this.controllerFor('juries.jury.creations.creation.panel').set('currentRoute', this.get('routeName'));
    }

  });

  _exports.default = _default;
});
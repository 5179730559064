define("da/templates/components/jury/president-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7ARCMfJg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content-president\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"president flex-100 flex-gt-md-80\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"jury\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"Président(e) du jury\"],[9],[0,\"\\n      \"],[2,\"<img src=\\\"{{image}}\\\">\"],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"info\"],[8],[0,\"\\n        \"],[7,\"b\",true],[8],[1,[24,[\"model\",\"president\",\"firstname\"]],false],[9],[0,\" \"],[7,\"b\",true],[8],[1,[24,[\"model\",\"president\",\"name\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n        \"],[1,[24,[\"model\",\"jury\",\"presidentIntro\"]],true],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"raised\",\"mini\",\"class\",\"onClick\"],[true,true,\"president-top bt-icon\",[28,\"action\",[[23,0,[]],\"switch\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"paper-icon\",[\"publish\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/jury/president-ui.hbs"
    }
  });

  _exports.default = _default;
});
define("da/serializers/creation-category", ["exports", "da/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    keyForRelationship(key, typeClass, method) {
      if (key === 'userCreation') {
        return 'user_creation';
      }

      return Ember.String.dasherize(key);
    }

  });

  _exports.default = _default;
});
define("da/services/vimeo", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    vimeo: _environment.default.vimeo,
    token: null,
    auth: Ember.inject.service(),

    /**
     * init service
     **/
    setup() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.authorize().then(token => {
          this.set('token', token.token);
          resolve();
        }, error => {
          reject();
        });
      });
    },

    /**
     * get quota upload
     **/
    quota() {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/vimeo/quota',
        type: "GET",
        dataType: "json",
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    ticket(file) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        var formData = new FormData();
        formData.append('video', file);
        Ember.$.ajax({
          url: _environment.default.host + '/api/v1/vimeo/ticket',
          type: 'POST',
          dataType: 'json',
          data: formData,
          processData: false,
          contentType: false,
          headers: {
            Authorization: this.get('auth').authorization()
          },
          success: function (response) {
            resolve(response);
          },
          error: function (error) {
            reject(error);
          }
        });
      });
    },

    check(url) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/vimeo/check',
        type: 'POST',
        data: {
          upload_link: url
        },
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    complete(url, uploadOffset) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/vimeo/publish',
        data: {
          url: url,
          'upload-offset': uploadOffset
        },
        type: 'POST',
        dataType: "json",
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    videos() {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/vimeo/videos',
        type: 'GET',
        dataType: "json",
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    findRecord(id) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/vimeo/videos/' + id,
        type: 'GET',
        dataType: "json",
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    },

    prev(path, creation_id) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/vimeo/prev',
        type: 'POST',
        dataType: "json",
        data: {
          path: path,
          creation_id: creation_id
        },
        headers: {
          Authorization: this.get('auth').authorization()
        }
      });
    }

  });

  _exports.default = _default;
});
define("da/routes/juries/jury/creations/creation/panel/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate: function () {
      this.controllerFor('juries.jury.creations.creation').set('currentRoute', this.get('routeName'));
    }
  });

  _exports.default = _default;
});
define("da/adapters/creation-category", ["exports", "da/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function (type) {
      return 'creation_categories';
    }
  });

  _exports.default = _default;
});
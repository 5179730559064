define("da/components/creation/audio-item", ["exports", "da/components/creation/item-ui"], function (_exports, _itemUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _itemUi.default.extend({
    audios: Ember.computed.alias('model.creation.audios'),
    hasAudio: Ember.computed('audios.length', function () {
      return this.get('audios.length') > 0 ? true : false;
    }),
    canUploadPrev: Ember.computed('audios.length', function () {
      if (this.get('audios.length') === 1) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});
define("da/controllers/palmares", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    palmares: Ember.inject.service(),
    actions: {
      clearFilter() {
        this.get('palmares').resetFilter();
      }

    }
  });

  _exports.default = _default;
});
define("da/templates/components/user/list-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fQ4ufJsP",
    "block": "{\"symbols\":[\"list\",\"key\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"limitedLists\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[\"user/user-item\"],[[\"key\",\"list\",\"login\"],[[23,2,[]],[23,1,[]],[24,[\"login\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"shouldLoad\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"more-loading\"],[8],[0,\"\\n    \"],[1,[22,\"paper-progress-circular\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"couldTop\"]]],null,{\"statements\":[[4,\"paper-button\",null,[[\"raised\",\"class\",\"onClick\"],[true,\"top-page bt-icon\",[28,\"action\",[[23,0,[]],\"scrollTop\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"paper-icon\",[\"arrow-upward\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/user/list-user.hbs"
    }
  });

  _exports.default = _default;
});
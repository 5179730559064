define("da/initializers/dialog-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'dialog', 'service:dialog');
    application.inject('controller', 'dialog', 'service:dialog');
    application.inject('component', 'dialog', 'service:dialog');
  }

  var _default = {
    name: 'dialog',
    initialize
  };
  _exports.default = _default;
});
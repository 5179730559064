define("da/models/creation-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    campagneName: _emberData.default.attr('string'),
    campagneId: _emberData.default.attr('string'),
    annonceur: _emberData.default.attr('string'),
    cover: _emberData.default.attr('string'),
    downloadImage: _emberData.default.attr('string'),
    downloadAudio: _emberData.default.attr('string'),
    creation: _emberData.default.belongsTo('creation'),
    category: _emberData.default.belongsTo('category'),
    userCreation: _emberData.default.belongsTo('user-creation'),
    videoPath: _emberData.default.attr('string'),
    isFavorite: _emberData.default.attr('boolean'),
    isShortlist: _emberData.default.attr('boolean'),
    isNominate: _emberData.default.attr('boolean'),
    isMention: _emberData.default.attr('boolean'),
    isPrice: _emberData.default.attr('boolean'),
    nbVotes: _emberData.default.attr('number'),
    nbVotesNo: _emberData.default.attr('number'),
    votesYes: _emberData.default.attr('number'),
    votesNo: _emberData.default.attr('number'),
    imagePreview: Ember.computed('cover', function () {
      return this.get('cover');
    }),
    hasCoverClassname: Ember.computed('cover', function () {
      if (this.get('cover')) return 'hasCover';
      return '';
    }),
    annonceurSort: Ember.computed('annonceur', function () {
      return this.get('annonceur').toLowerCase();
    })
  });

  _exports.default = _default;
});
define("da/templates/components/upload-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G7rA4j1W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"component\",[\"upload-ui/input-upload\"],[[\"file\",\"validate\"],[[24,[\"file\"]],[28,\"action\",[[23,0,[]],\"validate\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"component\",[\"upload-ui/button-upload\"],[[\"file\",\"maxFile\",\"validate\",\"name\"],[[24,[\"file\"]],[24,[\"maxFile\"]],[28,\"action\",[[23,0,[]],\"validate\"],null],[24,[\"name\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"component\",[\"upload-ui/upload-progress\"],[[\"file\",\"uploadedByte\",\"totalByte\"],[[24,[\"file\"]],[24,[\"uploadedByte\"]],[24,[\"totalByte\"]]]]],false],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/upload-file.hbs"
    }
  });

  _exports.default = _default;
});
define("da/router", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('auth', function () {
      this.route('login');
      this.route('register', {
        path: '/register/:type'
      }); //this.route('concours', {path: '/register/concours'});

      this.route('unregister');
      this.route('forget');
      this.route('reset', {
        path: 'reset/:token'
      });
    });
    this.route('wait');
    this.route('campagne', {
      path: '/'
    }, function () {
      this.route('validation');
      this.route('command');
      this.route('current_user');
      this.route('accounts');
      this.route('edit', {
        path: 'campagne/:campagne_id'
      }, function () {
        this.route('creation', function () {
          this.route('video', {
            path: '/:creation_id/video'
          });
          this.route('image', {
            path: '/:creation_id/image'
          });
          this.route('audio', {
            path: '/:creation_id/audio'
          });
        });
        this.route('categories');
        this.route('credits');
        this.route('synopsis');
      });
    });
    this.route('juries', function () {
      this.route('toc');
      this.route('auth', function () {
        this.route('login');
      });
      this.route('current_user');
      this.route('jury', {
        path: '/:jury_id'
      }, function () {
        this.route('creations', function () {
          this.route('creation', {
            path: '/:list_id'
          }, function () {
            this.route('panel', {
              path: '/'
            }, function () {
              this.route('comment');
              this.route('votes');
            });
          });
        });
      });
    });
    this.route('palmares', function () {
      this.route('show', {
        path: '/:list_id'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});
define("da/controllers/juries/jury/creations/creation/panel/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    campagne: Ember.computed.alias('model.voteService.creation.campagne'),
    routeEdit: Ember.computed('model.voteService.creation.creationType', function () {
      return 'campagne.edit.creation.' + this.get('model.voteService.creation.creationType');
    }),
    routeCampagneEdit: Ember.computed('model.voteService.creation.campagne', function () {
      return 'campagne.edit.creation.index';
    }),
    date: Ember.computed('campagne.datePublication', function () {
      var date = moment(this.get('campagne.datePublication'), 'DD/MM/YYYY');
      return date.format('MMMM') + ' ' + date.format('YYYY');
    })
  });

  _exports.default = _default;
});
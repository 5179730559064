define("da/routes/campagne/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.allSettled([this.get('auth').currentUser(), this.store.findAll('user-category')]).then(([user, userCategories]) => {
        user = user.value;
        var id = this.get('auth.loggedHas') ? this.get('auth.loggedHas.id') : user.data.id;
        this.store.pushPayload(user);
        return Ember.RSVP.hash({
          userApi: this.store.peekRecord('user-api', id),
          structures: userCategories.value
        });
      });
    }
  });

  _exports.default = _default;
});
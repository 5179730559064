define("da/components/categories/categories-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    click: function () {
      if (!this.get('category.categoryParent.isOpen')) {
        return false;
      }

      this.get('campagne.categories').removeObject(this.get('category'));
      this.get('campagne').save();
    }
  });

  _exports.default = _default;
});
define("da/services/palmares", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    data: [],
    category: null,
    searchProperties: ['annonceur', 'agency_name', 'category', 'libelle'],
    keyBoardListenner: true,
    hasPalmares: Ember.computed('filteredLists.length', function () {
      return this.get('filteredLists.length') > 0;
    }),
    categories: Ember.computed('data.length', 'selectedCategory', function () {
      return this.get('data').map(function (item) {
        return Ember.Object.create({
          id: item.get('parent_category_id'),
          libelle: item.get('parent_category_name')
        });
      }).uniqBy('id').sortBy('libelle');
    }),
    scores: Ember.computed('data.length', 'selectedScore', function () {
      return this.get('data').map(function (item) {
        var score = parseInt(item.get('score'));
        var libelle = 'Shortlist';

        if (score === 2) {
          libelle = '3ème Prix';
        }

        if (score === 3) {
          libelle = '2ème Prix';
        }

        if (score === 4) {
          libelle = '1er Prix';
        }

        return Ember.Object.create({
          id: item.get('score'),
          libelle: libelle
        });
      }).uniqBy('id').sortBy('id');
    }),

    setup() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.getPalmares().then(data => {
          this.set('data', Ember.ArrayProxy.create({
            content: Ember.A(data)
          }));
          data = this.get('data').map(function (item) {
            item.id = item.campagne_id + 'k' + item.children_category_id;
            return Ember.Object.create(item);
          });
          this.set('data', data);

          if (!Ember.isEmpty(this.get('category'))) {
            this.applyCategory();
          }

          resolve(data);
        }, error => {
          reject(error);
        });
      });
    },

    getPalmares() {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/palmares',
        type: "GET"
      });
    },

    show(campagne_id, category_id) {
      return Ember.$.ajax({
        url: _environment.default.host + '/api/v1/palmares/' + campagne_id + '/' + category_id,
        type: "GET"
      });
    },

    filteredLists: Ember.computed('data.length', 'search', 'selectedCategory', 'selectedScore', function () {
      var data = this.get('data');

      if (!Ember.isEmpty(this.get('selectedCategory'))) {
        data = data.filterBy('parent_category_id', this.get('selectedCategory.id'));
      }

      if (!Ember.isEmpty(this.get('selectedScore'))) {
        data = data.filterBy('score', this.get('selectedScore.id'));
      }

      if (!Ember.isEmpty(this.get('search'))) {
        data = data.filter(item => {
          var objects = item.getProperties(this.get('searchProperties'));
          return this.checkMatch(objects);
        });
      }

      return data;
    }),
    hasFilter: Ember.computed('search', 'selectedCategory', 'selectedScore', function () {
      return !Ember.isEmpty(this.get('search')) || !Ember.isEmpty(this.get('selectedCategory')) || !Ember.isEmpty(this.get('selectedScore')) ? true : false;
    }),

    resetFilter() {
      this.set('search', null);
      this.set('selectedCategory', null);
      this.set('selectedScore', null);
    },

    checkMatch(objects) {
      var match = false;

      for (var key in objects) {
        if (objects[key].toLowerCase().indexOf(this.get('search').toLowerCase()) != -1) {
          match = true;
          return true;
        }
      }

      return match;
    },

    applyCategory() {
      var id = parseInt(this.get('category'));
      var data = this.get('categories').findBy('id', id.toString());

      if (!Ember.isEmpty(data)) {
        this.set('selectedCategory', data);
      }
    }

  });

  _exports.default = _default;
});
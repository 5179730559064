define("da/models/user-creation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    creation: _emberData.default.belongsTo('creation'),
    category: _emberData.default.belongsTo('category'),
    shortlist: _emberData.default.attr('boolean'),
    nomination: _emberData.default.attr('boolean'),
    mention: _emberData.default.attr('boolean'),
    price: _emberData.default.attr('boolean'),
    jury: _emberData.default.belongsTo('jury'),
    nbVotes: _emberData.default.attr('number'),
    creationCategory: _emberData.default.belongsTo('creation-category'),
    favorite: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});
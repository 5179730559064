define("da/routes/auth/concours.deprecated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      let userApi = this.get('store').createRecord('user-api');
      return Ember.RSVP.allSettled([this.store.findAll('user-category')]).then(function ([userCategories]) {
        console.log('concours !!!');
        userApi.set('special', 'concours');
        return Ember.RSVP.hash({
          userApi: userApi,
          structures: userCategories.value
        });
      });
    }
  });

  _exports.default = _default;
});
define("da/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // Add your transitions here, like:
    this.transition(this.fromRoute('campagne.edit.creation'), this.toRoute('campagne.edit.categories'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('campagne.edit.creation'), this.toRoute('campagne.edit.credits'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('campagne.edit.creation'), this.toRoute('campagne.edit.synopsis'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('campagne.edit.categories'), this.toRoute('campagne.edit.credits'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('campagne.edit.categories'), this.toRoute('campagne.edit.synopsis'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('campagne.edit.credits'), this.toRoute('campagne.edit.synopsis'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('campagne.edit.creation.index'), this.toRoute('campagne.edit.creation.image'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('auth.login'), this.toRoute('auth.register'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('auth.login'), this.toRoute('auth.forget'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('auth'), this.toRoute('campagne'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('campagne.index'), this.toRoute('campagne.edit'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('juries.jury.creations.creation.panel.index'), this.toRoute('juries.jury.creations.creation.panel.comment'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('juries.jury.creations.creation.panel.index'), this.toRoute('juries.jury.creations.creation.panel.votes'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('juries.jury.creations.creation.panel.comment'), this.toRoute('juries.jury.creations.creation.panel.votes'), this.use('toLeft'), this.reverse('toRight'));
  }
});
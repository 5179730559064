define("da/routes/campagne/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      return this.store.findRecord('campagne', params.campagne_id, {
        reload: true
      });
    },

    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      controller.set('date', model.get('datePublication'));
      controller.set('cuurrentRoute', this.get('routeName'));
    }

  });

  _exports.default = _default;
});
define("da/controllers/juries/jury/creations/creation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    voteService: Ember.inject.service('vote'),
    lists: Ember.computed.alias('voteService.filteredLists'),
    path: Ember.computed('model.creation.creationType', function () {
      return 'jury/creation/' + this.get('model.creation.creationType') + '-item';
    }),

    checkIndex(index) {
      if (index < -1) {
        index = 0;
      } else if (index >= this.get('lists.length')) {
        index = this.get('lists.length') - 1;
      }

      return index;
    },

    actions: {
      next(index) {
        var index = this.checkIndex(index);
        var id = this.get('lists').objectAt(index).id;
        this.transitionToRoute(this.get('currentRoute'), id);
      },

      prev(index) {
        var index = this.checkIndex(index);
        var id = this.get('lists').objectAt(index).id;
        this.transitionToRoute(this.get('currentRoute'), id);
      }

    }
  });

  _exports.default = _default;
});
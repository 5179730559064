define("da/components/jury/president-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isVisible: Ember.computed('model.jury.presidentIntro', function () {
      return Ember.isEmpty(this.get('model.jury.presidentIntro')) ? false : true;
    }),
    image: Ember.computed('model.president.imageThumb', function () {
      return 'https://leclubdesda.org/' + this.get('model.president.imageThumb');
    }),
    actions: {
      switch() {
        this.$().find('.content-president').toggleClass('active');
        $('html, body').animate({
          scrollTop: 0
        }, 800);
      }

    }
  });

  _exports.default = _default;
});
define("da/routes/campagne", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    namespace: Ember.inject.service(),

    beforeModel() {
      this.set('namespace.namespace', 'api/v1');
      return this.get('auth').setup();
    },

    afterModel() {
      let competition = this.modelFor('application');
      let user = this.get('auth.getUser');
      /*if(!competition.get('isStarted') && !user.get('isAdmin')) {
      	return  this.transitionTo('wait');
      }*/
    },

    model() {
      return this.store.findAll('sector');
    },

    actions: {
      sessionChanged() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});
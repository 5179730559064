define("da/components/upload-ui/upload-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isVisible: Ember.computed('file', function () {
      if (this.get('file') != null) {
        return true;
      }

      return false;
    }),
    pourcent: Ember.computed('uploadedByte', 'totalByte', function () {
      return this.get('uploadedByte') * 100 / this.get('totalByte');
    })
  });

  _exports.default = _default;
});
define("da/components/categories/menu-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['layout-row', 'layout-wrap'],
    children: false,
    sortProperties: ['ordre:asc'],
    sortedItems: Ember.computed.sort('items', 'sortProperties'),
    click: function () {
      if (this.get('children')) {
        this.pushRecord();
      }
    },

    pushRecord() {
      this.get('campagne.categories').pushObject(this.get('item'));
    },

    actions: {
      changeClass: function (category_id) {
        document.querySelector('.category.category-c' + category_id).classList.toggle('active');
      }
    }
  });

  _exports.default = _default;
});
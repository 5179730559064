define("da/models/category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    libelle: _emberData.default.attr('string'),
    ordre: _emberData.default.attr('number'),
    categoryParent: _emberData.default.belongsTo('category-parent'),
    categoryParentName: _emberData.default.attr('string'),
    campagnes: _emberData.default.hasMany('campagne'),
    userCreations: _emberData.default.hasMany('user-creation'),
    detail: _emberData.default.attr('string'),
    pu: _emberData.default.attr('number'),
    px2: _emberData.default.attr('number'),
    px3: _emberData.default.attr('number'),
    price: Ember.computed('nbCreation', function () {
      var price = this.get('pu') * this.get('nbCreation');

      if (price > this.get('px3')) {
        return this.get('px3');
      }

      if (this.get('nbCreation') > 2 && !Ember.isEmpty(this.get('px3'))) {
        return this.get('px3');
      }

      return price;
    }),
    total: Ember.computed('nbCreation', 'price', function () {
      return this.get('price');
    }),
    creationCountArray: Ember.computed.mapBy('campagnes', 'nbCreations'),
    nbCreation: Ember.computed.sum('creationCountArray')
  });

  _exports.default = _default;
});
define("da/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showDialog: Ember.computed.alias('dialog.show'),
    title: Ember.computed.alias('dialog.title'),
    message: Ember.computed.alias('dialog.message'),
    params: Ember.computed.alias('dialog.params'),
    question: Ember.computed.alias('dialog.question'),
    labelYes: Ember.computed.alias('dialog.labelYes'),
    labelNo: Ember.computed.alias('dialog.labelNo'),
    actionOnAccept: Ember.computed('dialog.actionOnAccept', function () {
      if (this.get('dialog.actionOnAccept')) {
        return this.get('dialog.actionOnAccept');
      }

      return this.get('actions.closeDialog');
    }),
    actionOnRefuse: Ember.computed('dialog.actionOnRefuse', function () {
      if (this.get('dialog.actionOnRefuse')) {
        return this.get('dialog.actionOnRefuse');
      }

      return this.get('actions.closeDialog');
    }),
    onShow: Ember.observer('showDialog', function () {
      Ember.$(window).scrollTop(0);
    }),
    actions: {
      closeDialog: function () {
        this.get('dialog').reset();
      }
    }
  });

  _exports.default = _default;
});
define("da/routes/juries/jury/creations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    juryService: Ember.inject.service('jury'),
    voteService: Ember.inject.service('vote'),
    auth: Ember.inject.service(),
    model: function (params) {
      var competition = this.modelFor('application');
      var params = this.paramsFor('juries.jury');
      let controller = this.controllerFor('application');
      params.competition_id = competition.id;
      controller.set('currentlyLoading', true);
      return this.get('auth').currentUser().then(user => {
        this.get('store').pushPayload(user);
        this.set('voteService.user', this.store.peekRecord('user-api', user.data.id));
        var jury_id = this.paramsFor('juries.jury').jury_id;
        var jury = this.store.peekRecord('jury', jury_id);
        this.set('voteService.jury', jury);
        this.set('voteService.president', this.store.peekRecord('user-api', jury.get('presidentId')));
        this.get('voteService').setProperties({
          showShortlist: false,
          showNomination: false,
          showMention: false,
          showPrice: false
        });
        return this.store.query('creation-category', params).then(lists => {
          controller.set('currentlyLoading', true);
          this.get('voteService').resetFilter();
          this.set('voteService.lists', lists);
          return this.get('voteService');
        });
      });
    },

    setupController(controller, model) {
      var params = this.paramsFor('juries.jury'); // Call _super for default behavior

      this._super(controller, model); // Implement your custom setup after


      this.controllerFor('juries.jury').set('currentRoute', params.jury_id);
    }

  });

  _exports.default = _default;
});
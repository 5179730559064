define("da/models/competition", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    libelle: _emberData.default.attr('string'),
    annee: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    date_ouverture: _emberData.default.attr('date'),
    date_cloture: _emberData.default.attr('date'),
    title1: _emberData.default.attr('string'),
    text_history: _emberData.default.attr('string'),
    title_competition: _emberData.default.attr('string'),
    welcome_general: _emberData.default.attr('string'),
    welcome_competition: _emberData.default.attr('string'),
    welcome_student: _emberData.default.attr('string'),
    welcome_jury: _emberData.default.attr('string'),
    register_status: _emberData.default.attr('boolean'),
    register_student_status: _emberData.default.attr('boolean'),
    welcome_concours: _emberData.default.attr('string'),
    register_concours_status: _emberData.default.attr('boolean'),
    tab_creations: _emberData.default.attr('string'),
    tab_categories: _emberData.default.attr('string'),
    tab_credits: _emberData.default.attr('string'),
    tab_synopsis: _emberData.default.attr('string'),
    isOver: Ember.computed('date_cloture', function () {
      var date = (0, _moment.default)();
      var end = (0, _moment.default)(this.get('date_cloture'));
      return date.isAfter(end); //return true;
    }),
    isStarted: Ember.computed('date_ouverture', function () {
      var date = (0, _moment.default)();
      var start = (0, _moment.default)(this.get('date_ouverture'));
      return date.isAfter(start);
    })
  });

  _exports.default = _default;
});
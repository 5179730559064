define("da/controllers/auth/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),

    login(email, password) {
      this.get('auth').login(email, password).then(response => {
        this.get('auth').setToken(response.access_token, response.expire);
        this.transitionToRoute('campagne.index');
      }, error => {
        this.transitionToRoute('login', error);
      });
    },

    actions: {
      reset(model) {
        var params = model.getProperties('token', 'email', 'password', 'password_confirmation');
        this.get('auth').reset(params).then(success => {
          this.login(params.email, params.password);
        }, errors => {
          console.log(errors);
          this.set('errors', errors.responseJSON.errors);
        });
      }

    }
  });

  _exports.default = _default;
});
define("da/routes/juries/jury", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    namespace: Ember.inject.service(),
    model: function (params) {
      this.set('namespace.namespace', 'api/v1/jury');
      return Ember.RSVP.hash({
        juries: this.store.findAll('jury'),
        competition: this.modelFor('application')
      });
    }
  });

  _exports.default = _default;
});
define("da/components/upload-ui/input-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    isVisible: false,
    tagName: 'input',
    type: 'file',
    multiple: true,
    binary: '',
    file: '',
    classNames: ['file'],
    attributeBindings: ['name', 'disabled', 'form', 'type', 'accept', 'autofocus', 'required', 'multiple', 'dra'],

    change(event) {
      this.set('file', event.target.files[0]);
      this.get('validate')();
    }

  });

  _exports.default = _default;
});
define("da/templates/components/palmares/audio-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RSKVQvLz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"h3\",true],[10,\"style\",\"color:white;\"],[8],[0,\"“\"],[1,[22,\"title\"],false],[0,\"”\"],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[1,[22,\"audio\"],true],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "da/templates/components/palmares/audio-item.hbs"
    }
  });

  _exports.default = _default;
});
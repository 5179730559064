define("da/controllers/juries/jury/creations/creation/panel/votes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    voteService: Ember.inject.service('vote'),
    votes: Ember.computed.alias('model.votes'),

    /*dislikes: computed('votes.length', function() {
        return this.get('votes').filter(function(vote) {
            return vote.get('choice') == 0 ? true: false;
        });
    }),
     likes: computed('votes.length', 'votes.@each.choice', function() {
        return this.get('votes').filter(function(vote) {
            return vote.get('choice') == 1 ? true: false;
        });
    }),*/
    voted: Ember.computed('votes.length', 'votes.@each.hasVote', function () {
      return this.get('votes').filter(function (user) {
        return user.get('votes.length') > 0;
      });
    }),
    canMention: Ember.computed('voteService.favorite.shortlist', 'voteService.favorite.nomination', function () {
      return this.get('voteService.favorite.shortlist') && this.get('voteService.favorite.nomination');
    }),
    canPrice: Ember.computed('voteService.favorite.mention', function () {
      return this.get('voteService.favorite.mention');
    }),
    actions: {
      shorlist(model) {
        var shorlist = model.get('shortlist');

        if (shorlist) {
          model.setProperties({
            shortlist: false,
            nomination: false,
            mention: false,
            price: false
          });
        } else {
          model.set('shortlist', true);
        }

        model.save();
      },

      nomination(model) {
        var nomination = model.get('nomination');

        if (nomination) {
          model.setProperties({
            nomination: false,
            mention: false,
            price: false
          });
        } else {
          model.set('nomination', true);
        }

        model.save();
      },

      mention(model) {
        var mention = model.get('mention');

        if (mention) {
          model.setProperties({
            price: false,
            mention: false
          });
        } else {
          model.set('mention', true);
        }

        model.save();
      },

      price(model) {
        var price = model.get('price');

        if (price) {
          model.setProperties({
            price: false
          });
        } else {
          model.set('price', true);
        }

        model.save();
      }

    }
  });

  _exports.default = _default;
});
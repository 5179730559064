define("da/models/ceremony", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    awardEntity: _emberData.default.attr('string'),
    trophies: _emberData.default.attr('number'),
    onStage: _emberData.default.attr('string'),
    campagne: _emberData.default.belongsTo('campagne'),
    category: _emberData.default.belongsTo('category'),
    priceTrophy: 150,
    isValide: Ember.computed('awardEntity', 'onStage', function () {
      return !Ember.isEmpty(this.get('onStage')); //return !isEmpty(this.get('awardEntity')) &&  !isEmpty(this.get('onStage'));
    }),
    totalPriceTrophy: Ember.computed('trophies', function () {
      if (this.get('trophies') <= 1) {
        return 0;
      }

      return (this.get('trophies') - 1) * this.get('priceTrophy');
    })
  });

  _exports.default = _default;
});
define("da/components/creation/item-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['panel'],
    campagne: Ember.computed.alias('model.campagne'),
    images: Ember.computed.alias('model.creation.images'),
    image: Ember.computed.filterBy('images', 'imageType', 'image'),
    prev: Ember.computed.filterBy('images', 'imageType', 'prev'),
    videos: Ember.computed.alias('model.creation.videos'),
    normalVideo: Ember.computed.filterBy('videos', 'videoType', 'normal'),
    smallVideo: Ember.computed.filterBy('videos', 'videoType', 'small'),

    didRender() {
      this.$().animate({
        width: '90%'
      });
    },

    actions: {
      update(creation) {
        this.get('update')(creation);
      },

      deleteImage: function (image) {
        this.get('deleteImage')(image);
      },

      delete(creation) {
        this.get('delete')(creation);
      },

      back() {
        let self = this;
        this.$().animate({
          width: 0
        }, function () {
          self.get('back')();
        });
      }

    }
  });

  _exports.default = _default;
});
define("da/components/jury/nav-ui", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    voteService: Ember.inject.service('vote'),
    paperToaster: Ember.inject.service('paperToaster'),
    classNames: ['nav-jury', 'animated'],
    vote: Ember.computed.alias('voteService.vote'),
    favorite: Ember.computed.alias('voteService.favorite'),
    canFavorite: Ember.computed.alias('voteService.canAccessVotes'),
    isAdmin: Ember.computed.alias('voteService.user.isAdmin'),
    isSupervisor: Ember.computed.alias('voteService.user.isSupervisor'),
    keyBoardListenner: Ember.observer('voteService.keyBoardListenner', function () {
      var data = this.get('voteService.keyBoardListenner');
      this.setKeyBoardListenner(data);
    }).on('init'),
    activateKeyboard: Ember.on('init', function () {
      this.setKeyBoardListenner(true);
    }),

    setKeyBoardListenner(value) {
      this.set('keyboardActivated', value);
    },

    arrowLeft: Ember.on((0, _emberKeyboard.keyUp)('ArrowLeft'), function (event) {
      if (!this.get('disabledPrev')) {
        this.get('prev')(this.get('currentIndex') - 1);
      }
    }),
    arrowRight: Ember.on((0, _emberKeyboard.keyUp)('ArrowRight'), function (event) {
      if (!this.get('disabledNext')) {
        this.get('next')(this.get('currentIndex') + 1);
      }
    }),
    yes: Ember.computed('vote.choice', function () {
      return this.get('vote.choice') === 1 ? 'active' : '';
    }),
    no: Ember.computed('vote.choice', function () {
      if (this.get('vote.isNew') && this.get('vote.choice') != 0) {
        return '';
      }

      return this.get('vote.choice') === 0 ? 'active' : '';
    }),
    isFavorite: Ember.computed.alias('favorite.favorite'),
    lists: Ember.computed.alias('voteService.filteredLists'),
    current: Ember.computed.alias('voteService.creationCategory'),
    currentIndex: Ember.computed('current.id', 'lists.length', function () {
      var current = this.get('current'),
          lists = this.get('lists'),
          index = lists.indexOf(current);
      return index;
    }),
    disabledPrev: Ember.computed('currentIndex', function () {
      return this.get('currentIndex') == 0 ? true : false;
    }),
    disabledNext: Ember.computed('currentIndex', function () {
      return this.get('currentIndex') == this.get('lists.length') - 1 ? true : false;
    }),

    resetUi() {
      this.$().toggleClass('slideOutDown').delay(500).queue(() => {
        this.$().toggleClass('slideOutDown');
      });
    },

    actions: {
      next: function () {
        if (!this.get('disabledNext')) {
          this.get('next')(this.get('currentIndex') + 1);
        }
      },
      prev: function () {
        if (!this.get('disabledPrev')) {
          this.get('prev')(this.get('currentIndex') - 1);
        }
      },
      choice: function (choice) {
        this.get('vote').set('choice', choice);
        this.get('vote').save().then(vote => {
          this.get('voteService.creationCategory').reload();
          this.get('paperToaster').show('Votre vote a bien été pris en compte', {
            duration: 3000,
            position: 'top left',
            accent: true
          });
        });
      },
      favorite: function () {
        var favorite = this.get('favorite.favorite');
        var text = 'La campagne a bien été ajoutée aux favoris';

        if (favorite) {
          this.set('favorite.favorite', false);
          this.set('voteService.creationCategory.isFavorite', false);
          var text = 'La campagne a bien été supprimée des favoris';
        } else {
          this.set('favorite.favorite', true);
          this.set('voteService.creationCategory.isFavorite', true);
        }

        this.get('favorite').save().then(favorite => {
          this.get('paperToaster').show(text, {
            duration: 3000,
            position: 'top left',
            accent: true
          });
        });
      }
    }
  });

  _exports.default = _default;
});
define("da/components/palmares/audio-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['creation-audio'],
    title: Ember.computed('creation.audios.length', function () {
      return this.get('creation.libelle');
    }),
    audio: Ember.computed('creation.audios.length', function () {
      var path = this.get('creation.audios.firstObject.path_file');
      return '<audio controls><source src="' + path + '" type="audio/mp3"></audio>';
    })
  });

  _exports.default = _default;
});
define("da/components/audio-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    renderAudio: Ember.computed('audio.pathFile', function () {
      return '<audio controls><source src="' + this.get('audio.pathFile') + '" type="audio/mp3"></audio>';
    })
  });

  _exports.default = _default;
});
define("da/models/video", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    vimeoPath: _emberData.default.attr('string'),
    videoType: _emberData.default.attr('string'),
    creation: _emberData.default.belongsTo('creation'),
    vimeo: _emberData.default.belongsTo('vimeo-video')
  });

  _exports.default = _default;
});
define("da/controllers/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    title: Ember.computed('competition', function () {
      /*if (this.competition.libelle) {
        let title = this.competition.libelle.split('ᵉ').join('<sup>e</sup>');
        return title.split('°').join('<sup>e</sup>');
      }*/
      return 'Compétition & Concours';
    }),
    competition: Ember.computed(function () {
      return this.get('store').peekAll('competition').get('firstObject');
    })
  });

  _exports.default = _default;
});
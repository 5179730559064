define("da/controllers/palmares/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    palmares: Ember.inject.service(),
    lists: Ember.computed.alias('palmares.filteredLists'),
    opened: false,
    className: Ember.computed('opened', function () {
      if (this.get('opened')) {
        return 'panel-button active';
      }

      return 'panel-button';
    }),
    classBack: Ember.computed('opened', function () {
      if (this.get('opened')) {
        return 'hide back';
      }

      return 'back';
    }),
    lockedOpen: Ember.computed('opened', function () {
      return this.get('opened') ? 'lockedOpen' : '';
    }),

    checkIndex(index) {
      if (index < -1) {
        index = 0;
      } else if (index >= this.get('lists.length')) {
        index = this.get('lists.length') - 1;
      }

      return index;
    },

    actions: {
      next(index) {
        var index = this.checkIndex(index);
        var id = this.get('lists').objectAt(index).id;
        this.transitionToRoute(this.get('currentRoute'), id);
      },

      prev(index) {
        var index = this.checkIndex(index);
        var id = this.get('lists').objectAt(index).id;
        this.transitionToRoute(this.get('currentRoute'), id);
      },

      opened() {
        Ember.$('body').toggleClass('panel-open');

        if (this.get('opened')) {
          this.set('opened', false);
        } else {
          this.set('opened', true);
        }
      },

      close() {
        this.transitionToRoute('palmares');
      }

    }
  });

  _exports.default = _default;
});
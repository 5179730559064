define("da/serializers/user-creation", ["exports", "da/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot, options) {
      var json = this._super(...arguments);

      if (!Ember.isEmpty(json.data.relationships) && !Ember.isEmpty(json.data.relationships.creation)) {
        json.data.attributes['creation_id'] = json.data.relationships.creation.data.id;
      }

      if (!Ember.isEmpty(json.data.relationships) && !Ember.isEmpty(json.data.relationships.category)) {
        json.data.attributes['category_id'] = json.data.relationships.category.data.id;
      }

      if (!Ember.isEmpty(json.data.relationships) && !Ember.isEmpty(json.data.relationships.jury)) {
        json.data.attributes['jury_id'] = json.data.relationships.jury.data.id;
      }

      return json;
    }

  });

  _exports.default = _default;
});
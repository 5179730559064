define("da/services/competition", ["exports", "da/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),
    store: Ember.inject.service(),
    getCampagnes: function (campagne, type) {
      return $.ajax({
        url: _environment.default.host + '/api/v1/campagnes/all/' + campagne.get('id') + '/' + type,
        type: "GET",
        dataType: "json",
        headers: {
          Authorization: this.get('auth').authorization(),
          LoggedHas: this.get('auth.loggedHas.id')
        }
      });
    },
    getValidation: function () {
      return $.ajax({
        url: _environment.default.host + '/api/v1/campagnes/validation',
        type: "GET",
        dataType: "json",
        headers: {
          Authorization: this.get('auth').authorization(),
          LoggedHas: this.get('auth.loggedHas.id')
        }
      });
    },
    getCampagneValidation: function (ids) {
      return this.get('store').peekAll('campagne').filter(function (campagne) {
        return ids.includes(parseInt(campagne.id));
      });
    }
  });

  _exports.default = _default;
});